import { util, tooltip } from './_util.js'

const input = {
  init () {
    if (location.pathname === '/') {
      this.global()
      this.other()
      this.counter()
      this.anonymous()
    }
  },
  global () {
    document.querySelectorAll('input').forEach(function (v, i) {
      v.addEventListener('focusin', function () {
        this.classList.remove('invalid')
        tooltip.clear(this)
      })
      v.addEventListener('focusout', function () {
        if (!this.checkValidity()) this.classList.add('invalid')
      })
    })
    document.querySelectorAll('input[type=checkbox]').forEach(function (v) {
      v.addEventListener('change', function () {
        if (this.parentNode.querySelector('.tooltip')) {
          this.parentNode.querySelector('.tooltip').remove()
        }
      })
    })
  },
  other () {
    // var otherinput = document.querySelector('.other-input input')
    // otherinput.addEventListener('focusin', function () {
    //   this.setAttribute('type', 'tel')
    //   this.focus()
    //   this.value = this.getAttribute('input-realvalue')
    // })
    // otherinput.addEventListener('focusout', function () {
    //   this.setAttribute('type', 'text')
    //   this.setAttribute('input-realvalue', this.value)
    //   try {
    //     this.value = util.formatNumber(this.value, false)
    //     this.classList.remove('invalid')
    //   } catch (err) {
    //     this.classList.add('invalid')
    //   }
    // })
  },
  counter () {
    window.addEventListener('load', function () {
      document
        .querySelectorAll('[input-toggle=char-count]')
        .forEach(function (item) {
          item.addEventListener('keyup', function () {
            const charlimit = this.parentNode.querySelector('.char-limit')
            charlimit.style.display = 'initial'
            charlimit.innerHTML =
              this.value.length + '/' + this.getAttribute('maxlength')
          })
        })
    })
  },
  anonymous () {
    document
      .querySelector('#check-anonymous')
      .addEventListener('change', function () {
        /*
      document.querySelector('#feedname').removeAttribute(this.checked ? 'enabled' : 'disabled')
      document.querySelector('#feedname').setAttribute(this.checked ? 'disabled' : 'enabled', '')
      */
      })
  }
}

input.init()
