const data = null;
let util = null;
let include = null;
let tooltip = null;
let assets = null;
let _API_URI = process.env.API_URI;
let _INCLUDE_VERSION = process.env.SITE_VERSION;
let _SHARE_IMAGE_URI = process.env.SHARE_IMAGE_URI;

util = {
  getParam(param) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split("&");
    var sParameterName, i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split("=");

      if (sParameterName[0] === param) {
        return sParameterName[1] === undefined
          ? true
          : decodeURIComponent(sParameterName[1]);
      }
    }
  },
  resolveSVG(item) {
    var xhr = new XMLHttpRequest();
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        var svg = document.createElement("div");
        svg.innerHTML = this.responseText;
        let attr;
        const attributes = Array.prototype.slice.call(item.attributes);
        while (attr === attributes.pop())
          svg.setAttribute(attr.nodeName, attr.nodeValue);
        svg.classList.add("replaced-svg");
        item.parentNode.insertBefore(svg, item);
        item.parentNode.removeChild(item);
      }
    });
    var src = item.getAttribute("src");
    if (src == null) {
      src = item.getAttribute("data-src");
    }
    xhr.open("GET", src);
    xhr.send(data);
  },
  formatNumber(value, nodollar) {
    value = value.toString().replace("$", "");
    if (isNaN(parseFloat(value))) {
      throw new Error("Input not a valid number.");
    }
    if (parseFloat(value) % 1 !== 0) {
      value = parseFloat(value).toFixed(2);
    }
    value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    value = (nodollar === true ? "" : "$") + value;
    return value;
  },
  validateCC(n) {
    let c, r, e, d, i, t;
    for (
      e = !0,
        i = d = 0,
        t = (r = (n + "").replace(/\s/g, "").split("").reverse()).length;
      i < t;
      i++
    )
      (c = r[i]),
        (c = parseInt(c, 10)),
        (e = !e) && (c *= 2),
        9 < c && (c -= 9),
        (d += c);
    return d % 10 == 0;
  },
  containsEmojis(str) {
    return /\p{Extended_Pictographic}/u.test(str);
  },
  generateQrCode(url, targetSelector) {
    if (url === '')
      return false;

      // console.log('NEED QRCODE.js')

      var params = {        
        text: process.env.SITE_URI + url,
        width: 240,
        height: 240,
        colorDark: '#3e1a3b',
        colorLight: "#ffb5cc", //"#85C9C9",
        //logo: process.env.SITE_URI + '/img/mcgrath-logo_white.svg',
        //logoWidth: 80,
        //logoHeight: 80,
        //logoBackgroundColor: '#85C9C9', // Logo backgroud color, Invalid when `logBgTransparent` is true; default is '#ffffff'
        //logoBackgroundTransparent: true, // Whether use transparent image, default is false
        correctLevel: QRCode.CorrectLevel.H // L, M, Q, H
      }
    
    var qrcode = new QRCode(document.querySelector(targetSelector), params);

    return true;      
  }
};

tooltip = {
  clear(obj) {
    const err = obj.parentNode.querySelector(".tooltip");
    if (err) {
      err.remove();
    }
  },
  error(obj, text) {
    var cnt = document.createElement("div");
    cnt.classList.add("tooltip");
    cnt.classList.add("error");
    cnt.innerHTML = text;
    obj.parentNode.insertBefore(cnt, obj.this);
  },
};

// const includeGET = new Event("include-get");

include = {
  data: null,
  get(template, callback) {
    var xhr = new XMLHttpRequest();
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        var anchor = document.querySelector("anchor#" + template);
        if (anchor) {
          var content = document.createElement("div");
          content.innerHTML = this.responseText;
          anchor.parentNode.insertBefore(content, anchor);
          if (callback && typeof callback === "function") {
            callback.call();
          }
        }
      }
    });
    xhr.open("GET", "/includes/" + template + ".html?v=" + _INCLUDE_VERSION);
    xhr.send(this.data);
  },
};

assets = {
  add: {
    script(file) {
      var s = document.createElement("script");
      s.type = "text/javascript";
      s.src = file;
      document.querySelector("head").appendChild(s);
    },
    stylesheet(file) {
      var s = document.createElement("link");
      s.type = "text/css";
      s.rel = "stylesheet";
      s.href = file;
      document.querySelector("head").appendChild(s);
    },
  },
};

let customiserutil = {
  names(ctx, canvasObj, getLines, names, seatImage) {
    names.forEach(function (v, i) {
      let locations = {
        Single: [[(canvasObj.width / 2) - 35, canvasObj.height - 422, 320]],
        Double: [
          [350, canvasObj.height - 430, 280],
          [660, canvasObj.height - 430, 280],
        ],
        Multi3: [
          [400, canvasObj.height - 319, 200],
          [625, canvasObj.height - 319, 200],
          [507, canvasObj.height - 451, 200],
        ],
        Multi4: [
          [551, canvasObj.height - 324, 200],
          [776, canvasObj.height - 324, 200],
          [237, canvasObj.height - 458, 200],
          [461, canvasObj.height - 458, 200],
        ],
        Multi5: [
          [404, canvasObj.height - 320, 200],
          [609, canvasObj.height - 320, 200],
          [295, canvasObj.height - 460, 200],
          [505, canvasObj.height - 460, 200],
          [715, canvasObj.height - 460, 200],
        ],
        Group: [[(canvasObj.width / 2) - 35, canvasObj.height - 290, 360]],
        Ground: [[540, canvasObj.height - 300, 320]],
        SitWithMe: [
          [350, canvasObj.height - 430, 280],
          [660, canvasObj.height - 430, 280],          
        ],
      };
      let lines = getLines(ctx, v, locations[seatImage][i][2], ctx.font)
      lines.forEach(function (xv, xi) {
        if (seatImage == "Group") {
          ctx.fillStyle = "#3e1a3b";
        }
        if (locations[seatImage][i] != undefined) {
          let xpos = locations[seatImage][i][0] - ctx.measureText(xv).width / 2;
          let ypos = locations[seatImage][i][1] +
                      xi * (seatImage.indexOf("Multi") >= 0 ? 26 : seatImage.indexOf("Group") >= 0 ? 36 : 40)
                      - (lines.length > 1 ? seatImage.indexOf("Multi") >= 0 ? 14 : seatImage.indexOf("Group") >= 0 ? 18 : 24 : 0)

          ctx.fillText(xv, xpos, ypos);
        }
      });
    });
  },
  socialnames(ctx, canvasObj, getLines, names, seatImage) {
    names.forEach(function (v, i) {
      let locations = {
        Single: [[(canvasObj.width / 2) - 5, canvasObj.height - 225, 200]],
        Double: [
          [505, canvasObj.height - 230, 200],
          [690, canvasObj.height - 230, 200],
        ],
        Multi3: [          
          [534, canvasObj.height - 164, 120],
          [665, canvasObj.height - 164, 120],
          [598, canvasObj.height - 242, 120],
        ],
        Multi4: [
          [624, canvasObj.height - 168, 120],
          [754, canvasObj.height - 168, 120],          
          [440, canvasObj.height - 246, 120],
          [570, canvasObj.height - 246, 120],
        ],
        Multi5: [
          [539, canvasObj.height - 167, 120],
          [658, canvasObj.height - 167, 120],
          [475, canvasObj.height - 248, 120],
          [598, canvasObj.height - 248, 120],
          [718, canvasObj.height - 248, 120],
        ],
        Group: [[canvasObj.width / 2 - 8, canvasObj.height - 207, 220]],
        Ground: [[canvasObj.width / 2, canvasObj.height - 258, 200]],
        SitWithMe: [
          [505, canvasObj.height - 230, 200],
          [690, canvasObj.height - 230, 200],        
        ],
      };
      let lines = getLines(ctx, v, locations[seatImage][i][2], ctx.font)
      lines.forEach(function (xv, xi) {
        if (seatImage == "Group") {
          ctx.fillStyle = "#3e1a3b";
        }
        if (locations[seatImage][i] != undefined) {
          ctx.fillText(
            xv,
            locations[seatImage][i][0] - ctx.measureText(xv).width / 2,
            locations[seatImage][i][1] +
              xi * (seatImage.indexOf("Multi") >= 0 ? 16 : seatImage.indexOf("Group") >= 0 ? 20 : 24)
              - (lines.length > 1 ? seatImage.indexOf("Multi") >= 0 ? 8 : seatImage.indexOf("Group") >= 0 ? 8 : 12 : 0)
          );
        } 
      });
    });
  },
  sticker(ctx, canvasObj, sticker, seatImage, stickerContent, scale) {
    let sticker_image = new Image();
    sticker_image.src = stickerContent[sticker]["src"];
    sticker_image.onload = function () {
      if (scale !== null) { ctx.scale(scale,scale) }
      ctx.drawImage(
        sticker_image,
        stickerContent[sticker]["locations"][seatImage][0],
        canvasObj.height - stickerContent[sticker]["locations"][seatImage][1]
      );
      if (scale !== null) { ctx.scale(1,1) }
    };
  },
  savePersonalisation(id, type, options) {
    fetch(_API_URI + process.env.API_ENDPOINT_CUSTOMISATION, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        type: type,
        options: options,
      }),
    });
  },
  processVIP(id, options) {
    fetch(_API_URI + process.env.API_ENDPOINT_VIP + '?donationId=' + id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(options),
    });
  },
  getTextHeight(txt, font) {
    let el = document.createElement('div'), height;
    el.style.cssText = "position:fixed;padding:0;left:-9999px;top:-9999px;font:" + font;
    el.textContent = txt;
  
    document.body.appendChild(el); 
    height = parseInt(getComputedStyle(el).getPropertyValue('height'), 10);
    document.body.removeChild(el);

    return height;
  }
};

export { util, tooltip, include, assets, customiserutil, _API_URI, _SHARE_IMAGE_URI };
