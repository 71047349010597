import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
// import { range } from 'lodash'

import { _API_URI, _SHARE_IMAGE_URI, customiserutil } from './_util.js'
import { sitwithmeID1, sitwithmeID2, setID2 } from './sit-with-me.js'
import { Donation } from './donate-sit-with-me.js'
import { getLines } from './customiser'

function App () {
  const [activeTab, setActiveTab] = React.useState(1)
  const [cacheBuster, setCacheBuster] = React.useState('')
  const [isLoaded, setIsLoaded] = React.useState(false)
  const [names, setNames] = React.useState(['', ''])
  const [donationId, setDid] = React.useState('')
  const [stage, setStage] = React.useState('default')
  const siteURI = process.env.SITE_URI
  const seatImage = 'SitWithMe'
  const [init, setInit] = React.useState(false)
  const collectRefs = []
  const canvasRef = React.useRef(null)
  const canvasSocialRef = React.useRef(null)
  // const shareImageUrl = '/img/share/' + sitwithmeID1 + '.jpg'

  const [friendName, setFriendName] = React.useState('Your Mate')
  const [nameLoaded, setNameLoaded] = React.useState(false)
  const [hasDonated, setHasDonated] = React.useState(false)
  const [imageSaved, setImageSaved] = React.useState(false)

  useEffect(() => {
    initInvited(sitwithmeID1, sitwithmeID2)
  }, [])

  useEffect(() => {
    if (sitwithmeID1 > 0 && sitwithmeID2 > 0) {
      if (hasDonated) { setStage('thanks') } else { setStage('view') }
    }
  }, [sitwithmeID2])

  // Set stage view
  /* if (stage === 'default') {
    if (sitwithmeID1 > 0) {
      if (sitwithmeID2 > 0 && nameLoaded) {
        if (hasDonated) { setStage('thanks') } else { setStage('view') }
      } else { setStage('invited') }
    }
  } */

  function _setIsLoaded (input) {
    setIsLoaded(input)
  }

  function initInvited (did, did2) {
    const saveShareUrl = process.env.API_ENDPOINT_SITWITHME_FRIENDNAME + '/' + did
    fetch(saveShareUrl, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if (response.ok) return response.json()
        throw new Error('Network Error.')
      })
      .then((data) => {
        setNameLoaded(true)
        // setFriendName(data.FirstName + ' ' + data.LastName)
        // setNames([data.FirstName + ' ' + data.LastName, 'Your Name'])
        setFriendName(data.shareName !== '' ? data.shareName : data.firstName)
        setNames([data.shareName !== '' ? data.shareName : data.firstName, 'Your Name'])
        if (did2 > 0) {
          setStage('view')
        } else {
          setStage('invited')
        }
        setIsLoaded(true)
      })
  }

  function refreshImage () {
    //console.log('refreshImage')
    const canvasObj = canvasRef.current
    const ctx = canvasObj.getContext('2d')
    const canvasSocialObj = canvasSocialRef.current
    const ctx_social = canvasSocialObj.getContext('2d')

    canvasObj.width = 1080
    canvasObj.height = 1080
    canvasSocialObj.width = 1200
    canvasSocialObj.height = 628

    ctx.clearRect(0, 0, canvasObj.width, canvasObj.height)
    ctx.translate(ctx.canvas.width * 0.5, ctx.canvas.height * 0.5);    // center    

    const font_style = 'Bold 40px Linotte'
    const font_style_md = 'Bold 32px Linotte'

    const base_image = new Image()
    base_image.src = '/img/share/SHARE-DOUBLE.png'
    base_image.onload = function () {
      ctx.drawImage(base_image, 0, 0)
      ctx.rotate(-3.5 * Math.PI/180);   // 3.5°
      ctx.font = font_style_md
      ctx.fillStyle = '#fff'

      if (names) {
        customiserutil.names(ctx, canvasObj, getLines, names, seatImage)
      }

      if (!init) setInit(true)
    }
    ctx.translate(-ctx.canvas.width * 0.5, -ctx.canvas.height * 0.5);  // reset 

    
    // Social
    ctx_social.clearRect(0, 0, canvasSocialObj.width, canvasSocialObj.height)
    ctx_social.translate(ctx_social.canvas.width * 0.5, ctx_social.canvas.height * 0.5);    // center

    const social_image = new Image()
    social_image.src = '/img/share/SHARE-DOUBLE-SOCIAL.png'
    social_image.onload = function () {
      const font_style = 'Bold 24px Linotte'

      ctx_social.drawImage(social_image, 0, 0)
      ctx_social.rotate(-3.5 * Math.PI/180);   // 3.5°
      ctx_social.font = font_style
      ctx_social.fillStyle = '#fff'

      if (names) {
        customiserutil.socialnames(
          ctx_social,
          canvasSocialObj,
          getLines,
          names,
          seatImage
        )
      }
    }
    ctx_social.translate(-ctx_social.canvas.width * 0.5, -ctx_social.canvas.height * 0.5);  // reset 

    setCacheBuster(Date.now())
  }

  function _setActive (input) {
    setNames(
      collectRefs.map((e) => {
        return e.value
      })
    )
  }

  function _inviteFriends () {
    // Do Invite
  }

  function _setImageSaved () {
    setImageSaved(true)
  }

  function _saveImage (redir) {
    const imageData = canvasRef.current
      .toDataURL('image/jpeg', 0.9)
      .replace('data:image/jpeg;base64,', '')
    const imageDataSocial = canvasSocialRef.current
      .toDataURL('image/jpeg', 0.9)
      .replace('data:image/jpeg;base64,', '')

    const saveShareUrl =
      process.env.API_ENDPOINT_SITWITHMESHARE + '?cachebuster=' + cacheBuster
    fetch(saveShareUrl, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        Id: sitwithmeID1,
        Id2: sitwithmeID2,
        ImageType: 'share',
        Data: imageData,
        DataSocial: imageDataSocial
      })
    }).then(() => {
      // Done
      _setImageSaved()
      if (redir !== null && redir !== undefined && redir !== '') {
        window.location = redir
      }
    })
  }

  function _shareImage (ev) {
    const is_safari =
      navigator.userAgent.indexOf('Safari') > -1 &&
      navigator.userAgent.indexOf('Chrome') === -1
    // const buttonClasses = ev.target.classList;
    if (!is_safari) {
      window
        .open(
          ev.target.getAttribute('href'),
          ev.target.getAttribute('target') || ''
        )
        .focus()
    } else {
      window.location = ev.target.getAttribute('href')
    }
  }

  function _startInvite (ev) {
    if (navigator.canShare) {
      const shareElement = {
        url: `${siteURI}/sit-with-me/${sitwithmeID2}`,
        title:
            'Come Sit With Me - Help fund McGrath Breast Care Nurses -  NRMA Insurance Pink Test 2024',
        text: 'I’m inviting you to virtually come sit with me at this year’s NRMA Insurance Pink Test in support of the McGrath Foundation and families experiencing breast cancer. Get your Virtual Pink Seat next to mine!'
      }
      navigator.share(shareElement)
    } else {
      window.location = `/?invite=y&did=${sitwithmeID2}`
    }
  }

  // setData
  React.useEffect(() => {
    if ((stage === 'customise' || stage === 'invited') && friendName !== 'Your Mate' && isLoaded) {
      refreshImage()
    }
  }, [stage, seatImage, names, friendName, isLoaded])

  /*React.useEffect(() => {
    if ((stage === 'customise' || stage === 'invited') && friendName !== 'Your Mate' && isLoaded) {
      refreshImage()
    }
  }, [stage, isLoaded])*/

  return (
    <>
      {stage === 'default'
        ? (
        <div>
          <div className="row align-items-center justify-content-center mt-2 mb-2">
            <div className="col-11 my-5">
              <p className="text-center virtual-seats-share text-purple">
                Looking to donate to the McGrath Foundation Pink Test?
              </p>
              <a href="/" className="btn btn-primary btn-semi btn-lg w-100">
                Donate Now
              </a>
            </div>
          </div>
        </div>
          )
        : null}
        {!isLoaded
          ? (
          <div>
          <div className="row align-items-center justify-content-center mt-2 mb-2">
            <div className="col-11 my-5">
              <p className="text-center virtual-seats-share text-purple">
                Loading
              </p>
            </div>
          </div>
          </div>
            )
          : null
        }
      {stage === 'invited' && isLoaded
        ? (
          <div className="invited">
          <div className="row align-items-center justify-content-center mt-2 mb-2">
            <div className="col-11 col-lg-8 m-auto my-5 text-center text-purple">
              <h2 className="fs-2 my-4">{friendName} has invited you to virtually sit with them at the NRMA Insurance Pink Test!</h2>
              <p>
                Unite in pink and buy your Virtual Pink Seat alongside {friendName} this Pink Test! Your purchase will help ensure that 
                over 4,600 families have the support of a McGrath Breast Care Nurse this year.
              </p>
              <div>
                <div className="embed-responsive embed-responsive-1by1">
                  <canvas
                    className="bg-pink embed-responsive-item rounded"
                    ref={canvasRef}
                  ></canvas>
                </div>
                <canvas
                  className="d-none bg-pink rounded w-100"
                  ref={canvasSocialRef}
                ></canvas>
              </div>
              {nameLoaded
                ? (
              <div className="text-center text-purple mt-3 mb-4">
                  When you purchase your Virtual Pink Seat the image above will be updated with your name on the
                  seat next to {friendName}.
                </div>
                  )
                : null }
              <h2 className="fs-2 pt-3 mt-3 text-center">
                Virtual Pink Seat = $20
              </h2>
            {nameLoaded
              ? (
              <>
                <Donation FriendName={friendName} SetStage={setStage} SetNames={setNames} SaveImage={_saveImage} ImageSaved={imageSaved} HasDonated={setHasDonated} />
              </>
                )
              : null }

            </div>
          </div>
        </div>
          )
        : null }
      {(stage === 'customise' || stage === 'thanks' || stage === 'view') && isLoaded
        ? (
        <div>
          <div className="row">
            <div className="col-12 my-5">
              <h2 className="text-center virtual-seats-share text-purple">
                {stage === 'thanks'
                  ? (
                  <>[Thank You title and content]</>
                    )
                  : (
                  <>[View title and content]</>
                    )}

              </h2>
            </div>
            <div className="col-12 col-lg-6">
              <img
                src={`${_SHARE_IMAGE_URI}'/${sitwithmeID1}-${sitwithmeID2}.jpg?cachebuster${cacheBuster}`}
                className='w-100 bg-pink'
                loading='lazy'
                />
            </div>
            <div className="col-12 col-lg-6">

              <div className="mt-4 customiser__share text-center">
                <p className="mx-0 mx-lg-4 font-weight-bold d-none">
                  Unite in pink this NRMA Insurance Pink Test! Share your
                  Virtual Pink Seats with your family and friends.
                </p>
                <p className="d-none">
                  Don't forget to tag{' '}
                  <span className="text-pink">#PinkTest</span> and{' '}
                  <span className="text-pink">
                    <a
                      href="https://www.instagram.com/mcgrathfoundation/?hl=en"
                      target="_blank"
                    >
                      @mcgrathfoundation
                    </a>
                  </span>
                  .
                </p>
                <div className="social-share virtual-seats text-left font-weight-bold">
                  <ul className="row">
                    <li className="col-12">
                      <a
                        className="social-item dl mb my-2"
                        href={`${_SHARE_IMAGE_URI}/${sitwithmeID1}-${sitwithmeID2}.jpg?cachebuster${cacheBuster}`}
                        target="_blank"
                        onClick={_shareImage}
                      >
                        Download <i className="fas fa-download"></i>
                      </a>
                    </li>
                    {navigator.canShare && window.innerWidth < 1024
                      ? (
                      <li className="col-12">
                        <button
                          className="social-item ntv mb my-2"
                          onClick={() => {
                            canvasRef.current.toBlob((blob) => {
                              const shareElement = {
                                url: `https://${siteURI}/share/sit-with-me/${sitwithmeID1}-${sitwithmeID2}`,
                                title:
                                  'Help fund McGrath Breast Care Nurses -  NRMA Insurance Pink Test 2024',
                                text: 'The NRMA Insurance Pink Test is back in 2024. No matter where you are, unite in pink to support families experiencing breast cancer.',
                                files: [
                                  new File([blob], 'Pink Test 2024.jpg', {
                                    type: blob.type,
                                    lastModified: new Date().getTime()
                                  })
                                ]
                              }
                              navigator.share(shareElement)
                            })
                            // alert("Sharing currently disabled.");
                          }}
                        >
                          <i className="fas fa-share-alt"></i>
                          Share Now
                        </button>
                      </li>
                        )
                      : null}
                    {!navigator.canShare || window.innerWidth > 1024
                      ? (
                      <>
                        {' '}
                        <li className="col-12 col-lg-6">
                          <a
                            className="social-item fb my-2"
                            href={`https://www.facebook.com/sharer/?u=${siteURI}/share/sit-with-me/${sitwithmeID1}-${sitwithmeID2}`}
                            target="_blank"
                            onClick={_shareImage}
                          >
                            Facebook <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li className="col-12 col-lg-6">
                          <a
                            className="social-item tw my-2"
                            href="https://twitter.com/share"
                            data-url={`${siteURI}/share/sit-with-me/${sitwithmeID1}-${sitwithmeID2}`}
                            data-related="McGrathFdn"
                            data-text="I donated to make a difference!"
                            data-dnt="true"
                            target="_blank"
                            onClick={_shareImage}
                          >
                            Twitter <i className="fab fa-twitter"></i>
                          </a>
                        </li>{' '}
                      </>
                        )
                      : null}
                  </ul>
                  <script
                    async=""
                    src="https://platform.twitter.com/widgets.js"
                    charSet="utf-8"
                  ></script>
                </div>
                <p className="mt-3 mb-0 d-none">
                  Please note, Virtual Pink Seats are not redeemable as tickets
                  to the NRMA Insurance Pink Test at the SCG 3rd - 7th January
                  2024.
                </p>
              </div>

              <p>
                You can start your own section and invite your mates to sit with you.
              </p>

              <button onClick={_startInvite} className="sitwithme__btn d-block my-4">Start My Own</button>

            </div>
            {stage === 'thanks'
              ? (
            <div className="col-12 mt-5">
            <div className="d-flex align-items-center flex-wrap justify-content-center">

              <div className="col-lg-8 text-center">
                <p className="text-purple">
                  <strong>Have a question regarding your purchase? Contact us on <a href="tel:0289626100">(02) 8962 6100</a> or at <a href="mailto:pinktest@mcgrathfoundation.com.au">pinktest@mcgrathfoundation.com.au</a>.</strong>
                </p>
                <p className="text-purple">
                  Tax receipts are sent to the email address provided and may take up to a few hours to be received.
                </p>
                <p className="text-purple">
                  Virtual Pink Seats are not redeemable as tickets to the NRMA Insurance Pink Test at the SCG 3 - 7 January 2024.
                </p>
              </div>
            </div>
            </div>
                )
              : null }
          </div>
        </div>
          )
        : null}
    </>
  )
}

if (document.querySelector('#customiser-sitwithme')) {
  ReactDOM.render(<App />, document.querySelector('#customiser-sitwithme'))
}
