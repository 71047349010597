import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { range } from 'lodash'
import cc from 'classcat'
import { _API_URI, _SHARE_IMAGE_URI, customiserutil, util } from './_util.js'
import { Accordion, stickerContent, getLines } from './customiser'

const shareImages = {
  Single: [
    '/img/share/SHARE-SINGLE.png',
    '/img/share/SHARE-SINGLE-MESSAGE.png'
  ],
  Double: [
    '/img/share/SHARE-DOUBLE.png',
    '/img/share/SHARE-DOUBLE-MESSAGE.png'
  ],
  Multi3: [
    '/img/share/SHARE-MULTI3.png',
    '/img/share/SHARE-MULTI3-MESSAGE.png'
  ],
  Multi4: [
    '/img/share/SHARE-MULTI4.png',
    '/img/share/SHARE-MULTI4-MESSAGE.png'
  ],
  Multi5: [
    '/img/share/SHARE-MULTI5.png',
    '/img/share/SHARE-MULTI5-MESSAGE.png'
  ],
  Group: ['/img/share/SHARE-GROUP.png', '/img/share/SHARE-GROUP-MESSAGE.png']
}

const shareSocialImages = {
  Single: '/img/share/SHARE-SINGLE-SOCIAL.jpg',
  Double: '/img/share/SHARE-DOUBLE-SOCIAL.jpg',
  Multi3: '/img/share/SHARE-MULTI3-SOCIAL.jpg',
  Multi4: '/img/share/SHARE-MULTI4-SOCIAL.jpg',
  Multi5: '/img/share/SHARE-MULTI5-SOCIAL.jpg',
  Group: '/img/share/SHARE-GROUP-SOCIAL.jpg'
}

function App () {
  const urlParams = new URLSearchParams(window.location.search)
  const [activeTab, setActiveTab] = React.useState(1)
  const [cacheBuster, setCacheBuster] = React.useState('')
  const [isLoaded, setIsLoaded] = React.useState(false)
  const [init, setInit] = React.useState(false)
  const [names, setNames] = React.useState([''])
  const [sticker, setSticker] = React.useState('')
  const [message, setMessage] = React.useState('')
  const [messageSet, setMessageSet] = React.useState(false)
  const [donationId, setDid] = React.useState(parseInt(localStorage.getItem('pt24_did')) || 0)
  const [stage, setStage] = donationId === 0 ? React.useState('register') : React.useState('customise')
  const [seatImage, setSeatImage] = React.useState('Single')
  const [termsCheck, setTermsCheck] = React.useState(false)
  const siteURI = process.env.SITE_URI
  // const [isAdmin, setIsAdmin] = React.useState(parseInt(urlParams.get('isadmin')) === 1 || false)
  const isAdmin = parseInt(urlParams.get('isadmin')) === 1 || false

  const collectRefs = []
  const canvasRef = React.useRef(null)
  const canvasSocialRef = React.useRef(null)

  /* if (donationId === 0) { window.location = '/' } */
  if (donationId > 0 && !isAdmin) { setDataCustomiser(donationId) } // Initial load
  else if (isAdmin) { setAdminCustomiser() }

  function _setIsLoaded (input) {
    setIsLoaded(input)
  }

  function setDataCustomiser (donationId) {
    if (!isLoaded) {
      getPersonalisation(donationId)
    }
  }

  function setAdminCustomiser () {
    if (!isLoaded) {
      _setIsLoaded(true)

      // Set Data
      let stickerName = urlParams.get('sticker')
      stickerName = stickerName === 'aus' ? 'Aussie Sticker' : stickerName === 'pa' ? 'Pakistan Sticker' : stickerName === 'mcgrath' ? 'McGrath Sticker' : stickerName === 'mfpin' ? 'McGrath Pin' : ''

      setSeatImage(urlParams.get('seat'))
      setNames(urlParams.get('names').split(','))
      setSticker(stickerName)
      setMessage(urlParams.get('message'))
      setStage('customise')
    }
  }

  function refreshImage () {
    const canvasObj = canvasRef.current
    const canvasSocialObj = canvasSocialRef.current
    const ctx = canvasObj.getContext('2d')
    const ctx_social = canvasSocialObj.getContext('2d')

    canvasObj.width = 1080
    canvasObj.height = 1080 //message === '' ? 1080 : 1350
    canvasSocialObj.width = 1200
    canvasSocialObj.height = 628

    ctx.clearRect(0, 0, canvasObj.width, canvasObj.height)
    ctx.translate(ctx.canvas.width * 0.5, ctx.canvas.height * 0.5);    // center

    const font_style = 'Bold 40px Linotte'
    const font_style_md = 'Bold 32px Linotte'
    const font_style_sm = 'Bold 24px Linotte'
    const font_style_share = 'Bold Italic 32px Linotte'

    const base_image = new Image()
    base_image.src = shareImages[seatImage][0] //shareImages[seatImage][message === '' ? 0 : 1]
    base_image.onload = function () {
      
      ctx.drawImage(base_image, 0, 0)
      ctx.rotate(-3.5 * Math.PI/180);   // 3.5°
      ctx.font = seatImage === 'Double' || seatImage === 'Group' ? font_style_md : seatImage.indexOf('Multi') >= 0 ? font_style_sm : font_style
      ctx.fillStyle = '#fff'

      if (names) {
        customiserutil.names(ctx, canvasObj, getLines, names, seatImage)
      }
      if (sticker) {
        customiserutil.sticker(
          ctx,
          canvasObj,
          sticker,
          seatImage,
          stickerContent,
          0.8
        )
      }

      if (message) {      
        // Main text
        getLines(ctx, message, 550, font_style_share).forEach(function (v, i) {
          ctx.font = font_style_share
          ctx.fillStyle = '#3e1a3b'
          ctx.fillText(
            v,
            (canvasObj.width / 2 - ctx.measureText(v).width / 2) - 40,
            360 + 44 * i
          )
        })
      }

       if (!init) setInit(true)
    }
    ctx.translate(-ctx.canvas.width * 0.5, -ctx.canvas.height * 0.5);  // reset 


    // Social
    ctx_social.clearRect(0, 0, canvasSocialObj.width, canvasSocialObj.height)
    ctx_social.translate(ctx_social.canvas.width * 0.5, ctx_social.canvas.height * 0.5);    // center

    const social_image = new Image()
    social_image.src = shareSocialImages[seatImage]
    social_image.onload = function () {
      const font_style = 'Bold 24px Linotte'
      const font_style_sm = 'Bold 16px Linotte'

      ctx_social.drawImage(social_image, 0, 0)
      ctx_social.rotate(-3.5 * Math.PI/180);   // 3.5°
      ctx_social.font = seatImage.indexOf('Multi') >= 0 || seatImage === 'Group' ? font_style_sm : font_style
      ctx_social.fillStyle = '#fff'

      if (names) {
        customiserutil.socialnames(
          ctx_social,
          canvasSocialObj,
          getLines,
          names,
          seatImage
        )
      }
    }
    ctx_social.translate(-ctx_social.canvas.width * 0.5, -ctx_social.canvas.height * 0.5);  // reset 

    setCacheBuster(Date.now())
  }

  /*useEffect(() => {
    _saveImageInit()
  }, [init])  */

  function _setSeatImage (input) {
    setSeatImage(input)
  }

  function _setSticker (input) {
    if (input === sticker) setSticker('')
    else setSticker(input)
  }

  function _setActive (input) {
    setNames(
      collectRefs.map((e) => {
        return e.value
      })
    )
  }

  function getPersonalisation (id) {
    //    setIsLoaded(true)
    fetch(_API_URI + process.env.API_ENDPOINT_GETCUSTOMISATION + '/' + id, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      if (response.ok) return response.json()
      throw new Error('Network Error.')
    }).then((response) => {
      if (response.id !== undefined) {
        // Set Data
        setSeatImage(response.options.seatType || 'Single')
        setNames(response.options.seats || '')
        setSticker(response.options.sticker || '')
        setMessage(response.options.message || '')
        setStage('customise')

        _setIsLoaded(true)
      }
    })
      .catch((error) => {
        console.error(error)
      })
  }

  function _saveImage (ev) {
    if (ev) ev.preventDefault()

    /* customiserutil.savePersonalisation(donationId, 'myseat', {
      SeatType: seatImage,
      Seats: names,
      Message: message,
      Sticker: sticker
    }) */

    /* const imageData = canvasRef.current
      .toDataURL('image/jpeg', 0.75)
      .replace('data:image/jpeg;base64,', '')
    const imageDataSocial = canvasSocialRef.current
      .toDataURL('image/jpeg', 0.8)
      .replace('data:image/jpeg;base64,', '')

    if (canvasRef.current.width !== 1080) {
      return
    } */

    /* const saveShareUrl = (isAdmin
      ? process.env.API_ENDPOINT_MYSEATSHARE
      : process.env.API_ENDPOINT_SHARE) +
     '?cachebuster=' + cacheBuster
    fetch(saveShareUrl, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        Id: donationId,
        ImageType: 'ground',
        Data: imageData,
        DataSocial: imageDataSocial
      })
    }).then(() => { */
    const is_safari =
        navigator.userAgent.indexOf('Safari') > -1 &&
        navigator.userAgent.indexOf('Chrome') === -1
    // const buttonClasses = ev.target.classList;
    if (!is_safari) {
      window
        .open(
          ev.target.getAttribute('href'),
          ev.target.getAttribute('target') || ''
        )
        .focus()
    } else {
      // var link = document.createElement("a");
      // link.download = "Pink Test 2022.jpg";
      // link.href = canvasRef.current.toDataURL("image/jpeg", 0.8);
      // link.click();
      window.location = ev.target.getAttribute('href')
    }
    /* }) */
  }

  function _openImage (ev) {
    if (ev) ev.preventDefault()

    const el = ev.target
    const size = parseInt(el.getAttribute('data-size'))
    const canvasObj = (size > 1080) ? canvasSocialRef.current : canvasRef.current
    //const dataUrl = canvasObj.toDataURL('image/png')

    const imageData = canvasObj
      .toDataURL('image/jpeg', 1)
      .replace('data:image/jpeg;base64,', '')
    
    //console.log(dataUrl)
    window.open(imageData, '_blank')// .focus()
  }

  function _showInvite (ev) {
    if (ev) { ev.preventDefault() }
    const did = donationId
    const inviteImg = document.getElementById('invite_image')
    const inviteEls = document.querySelectorAll('.invite-button-area, .invite-instructions')
    const inviteQRTextArea = document.querySelector('.invite-qrtext-area')

    if (navigator.canShare && window.innerWidth < 1024) {
      inviteImg.setAttribute('src', _SHARE_IMAGE_URI + '/' + did + '-0.jpg') // imageInviteData)
      inviteImg.classList.remove('d-none')
      inviteQRTextArea.classList.add('d-none')
      inviteEls.forEach((el) => { el.classList.remove('d-none') })
      const inviteBtns = document.querySelectorAll('#invite_button, #invite_button_pre')
      inviteBtns.forEach((inviteBtn) => {
        inviteBtn.addEventListener('click', (e) => {
          e.preventDefault()
          const shareElement = {
            url: `${siteURI}/share/sit-with-me/${did}-0`,
            title: 'Come Sit With Me - Help fund McGrath Breast Care Nurses -  NRMA Insurance Pink Test 2024',
            text: 'The NRMA Insurance Pink Test is back in 2024. No matter where you are, unite in pink to support families experiencing breast cancer.'
          }
          navigator.share(shareElement)
        })
      })
    } else {
      if (document.querySelector('#invite_qr_container canvas') === null) {
        util.generateQrCode('?invite=y&did=' + did, '#invite_qr_container')
      }
      inviteImg.classList.add('d-none')
      inviteQRTextArea.classList.remove('d-none')
      inviteEls.forEach((el) => { el.classList.add('d-none') })
    }

    document.querySelector('body').classList.add('noscroll')
    document.querySelector('#modal-sit-with-me').style.display = 'block'
    document.querySelector('#modal-sit-with-me').classList.add('visible')
  }

  const [mySeatValid, setMySeatValid] = useState(false)
  const [mySeatInput, setMySeatInput] = useState('')
  const [mySeatLoading, setMySeatLoading] = useState(false)
  const [corporateError, setMySeatError] = useState('')

  // setData
  React.useEffect(() => {
    if (stage === 'customise' && isLoaded) {
      refreshImage()
    }
  }, [stage, seatImage, names, sticker, isLoaded])

  /*React.useEffect(() => {
    if (stage === 'customise' && isLoaded) {
      refreshImage()
    }
  }, [stage, isLoaded, init])*/

  const _setMySeatInput = (input) => {
    setMySeatValid(/^\S+@\S+\.\S+$/.test(input.target.value))
    setMySeatInput(input.target.value)
  }

  return (
    <>
      {stage === 'register'
        ? (
        <div>
          <div className="row align-items-center justify-content-center mt-2 mb-2">
            <div className="col-11 my-5">
              <h2 className="text-center virtual-seats-share text-purple d-none">
                To claim and personalise your virtual pink seat, please enter
                your email address.
              </h2>
              <h2 className="text-center virtual-seats-share text-purple">
                Looking to buy your Virtual Seats?
              </h2>
              <p>
                <a href="/" className="btn btn-primary btn-sm m-auto">Buy Now</a>
              </p>
            </div>
            <form
              onSubmit={(ev) => {
                ev.preventDefault()
              }}
              className="col-12 col-md-8 col-lg-6 mb-5 d-none"
            >
              <label htmlFor="email">Your Email Address</label>
              <input
                type="email"
                autoComplete="email"
                id="corporateEmail"
                name="corporateEmail"
                placeholder=""
                required=""
                value={mySeatInput}
                onChange={_setMySeatInput}
              />
              {corporateError
                ? (
                <div className="text-danger p-3">{corporateError}</div>
                  )
                : null}
              <h4 className="d-flex justify-content-center align-items-center">
                <input
                  placeholder=" "
                  required=""
                  className="ml-3 mr-3"
                  type="checkbox"
                  id="check-terms"
                  name="terms"
                  value=""
                  noValidate=""
                  checked={termsCheck}
                  onChange={setTermsCheck}
                />
                <label
                  htmlFor="check-terms"
                  className="d-flex align-items-center w-100"
                  style={{ fontSize: 'inherit' }}
                >
                  <span style={{ fontSize: 'inherit' }}>
                    I have read the{' '}
                    <a
                      data-toggle="modal"
                      data-modal="terms"
                      href="#"
                      onClick={() => {
                        document
                          .querySelector('body')
                          .classList.add('noscroll')
                        document.querySelector('#modal-terms').style.display =
                          'block'
                        document
                          .querySelector('#modal-terms')
                          .classList.add('visible')
                      }}
                    >
                      Terms &amp; Conditions
                    </a>
                  </span>
                </label>
              </h4>
              <button
                type={'submit'}
                className={
                  mySeatValid
                    ? 'corporate__btn'
                    : 'corporate__btn corporate__btn_disabled'
                }
                onClick={() => {
                  if (!mySeatValid) return
                  if (mySeatLoading) return
                  if (!termsCheck) {
                    setMySeatError('Please agree to the terms.')
                    return
                  }

                  setMySeatLoading(true)

                  fetch(
                    _API_URI +
                      process.env.API_ENDPOINT_MYSEATEMAIL +
                      '?' +
                      new URLSearchParams({
                        email: mySeatInput
                      })
                  )
                    .then((response) => {
                      if (response.ok) return response.json()
                      throw Error('Something went wrong.')
                    })
                    .then((json) => {
                      if (json.result === true) {
                        setDid(json.did)
                        setMySeatLoading(false)
                        setStage('customise')
                      } else {
                        setMySeatLoading(false)
                        setMySeatError(json.message)
                      }
                    })
                    .catch((error) => {
                      console.error(error)
                      setMySeatLoading(false)
                      setMySeatError(
                        "We couldn't reach the server at this time. Please refresh and try again, if the problem persists, try later."
                      )
                    })
                }}
              >
                {mySeatLoading
                  ? (
                  <>Loading...</>
                    )
                  : (
                  <>Personalise your Virtual Pink Seat</>
                    )}
              </button>
            </form>
          </div>
        </div>
          )
        : null}
      {!isLoaded
        ? (
          <div className="row my-5">
            <div className="col-12 col-lg-6">
              <p className="text-center virtual-seats-share text-purple">
                Loading
              </p>
            </div>
          </div>
          )
        : null }
      {(stage === 'customise' || stage === 'thanks') && isLoaded
        ? (
        <div className="row my-5">
          <div className="col-12 col-lg-6">
            <div 
              className={'embed-responsive embed-responsive-1by1'
                //message === ''
                //  ? 'embed-responsive embed-responsive-1by1'
                //  : 'embed-responsive embed-responsive-4by5'
              }            
            >
              <canvas
                id="myseat_canvas"
                className={cc([
                  !isAdmin ? 'd-none' : null,
                  'bg-pink embed-responsive-item rounded'
                ])}
                ref={canvasRef}
              ></canvas>
              <img src={`${_SHARE_IMAGE_URI}/${donationId}.jpg`}
                className={cc([
                  isAdmin ? 'd-none' : null,
                  'bg-pink embed-responsive-item rounded'
                ])}
                id="myseat_image" />
            </div>
            <canvas
              className={cc([
                !isAdmin ? 'd-none' : null,
                'bg-pink bg-pink rounded w-100 mt-4'
              ])}
              ref={canvasSocialRef}
            ></canvas>
          </div>
          <div className="col-12 col-lg-6">
            <div>
              <Accordion
                title={'Edit name'}
                index={1}
                active={activeTab === 1}
                stateSetter={setActiveTab}
              >
                <fieldset>
                  {range(1).map((i, v) => (
                    <div className="char-offset position-relative" key={i}>
                      <input
                        className="mt-1 mb-2"
                        type="text"
                        value={names[i]}
                        key={i}
                        id={v}
                        onChange={_setActive}
                        maxLength={25}
                        ref={(el) => {
                          collectRefs.push(el)
                        }}
                      />
                      <span className="char-limit">
                        {names[i] ? names[i].length : 0}/{25}
                      </span>
                    </div>
                  ))}
                </fieldset>
              </Accordion>
              <Accordion
                title="Add a sticker"
                index={2}
                active={activeTab === 2}
                stateSetter={setActiveTab}
              >
                <div className="sticker__wrapper">
                  {Object.keys(stickerContent).map((v) => (
                    <button
                      className={
                        sticker === v ? 'sticker sticker__active' : 'sticker'
                      }
                      key={v}
                      onClick={() => _setSticker(v)}
                      title={v}
                    >
                      <img
                        src={stickerContent[v].thumbnail}
                        className="sticker__thumb"
                      />
                    </button>
                  ))}
                </div>
              </Accordion>
            </div>
            <div className="mt-4 customiser__share text-center">
            {!isAdmin
              ? (
              <p className="mx-0 mx-lg-4 font-weight-bold">
                Unite in pink this NRMA Insurance Pink Test! Share your
                Virtual Pink Seats with your family and friends.
              </p>
                )
              : (
                <p className="mx-0 mx-lg-4 font-weight-bold d-none">
                  Download the 1080x1080 image or the 1200x623 image.
                </p>
                )
              }
            {!isAdmin
              ? (
              <p>
                Don't forget to tag{' '}
                <span className="text-pink">#PinkTest</span> and{' '}
                <span className="text-pink">
                  <a
                    href="https://www.instagram.com/mcgrathfoundation/?hl=en"
                    target="_blank"
                  >
                    @mcgrathfoundation
                  </a>
                </span>
                .
              </p>
                )
              : null}
              <div className="social-share virtual-seats text-left font-weight-bold">
                <ul className="row">
                {!isAdmin && (navigator.canShare && window.innerWidth < 1024)
                  ? (
                    <li className="col-12">
                      <button
                        className="social-item ntv mb my-2 btn"
                        onClick={() => {
                          canvasRef.current.toBlob((blob) => {
                            const shareElement = {
                              url: `${siteURI}/share/${donationId}`,
                              title:
                                'Help fund McGrath Breast Care Nurses -  NRMA Insurance Pink Test 2024',
                              text: 'The NRMA Insurance Pink Test is back in 2024. No matter where you are, unite in pink to support families experiencing breast cancer.',
                              files: [
                                new File([blob], 'Pink Test 2024.jpg', {
                                  type: blob.type,
                                  lastModified: new Date().getTime()
                                })
                              ]
                            }
                            navigator.share(shareElement)
                          })
                        }}
                      >
                        <i className="fas fa-share-alt"></i>
                        Save &amp; Share
                      </button>
                    </li>
                    )
                  : null}
                  {!isAdmin
                    ? (
                  <li className="col-12">
                    <a
                      className="social-item dl mb my-2 btn"
                      href={`${_SHARE_IMAGE_URI}/${donationId}.jpg?cachebuster=${cacheBuster}`}
                      target="_blank"
                      onClick={_saveImage}
                    >
                      Download <i className="fas fa-download"></i>
                    </a>
                  </li>
                      )
                    : (
                    <>
                    <li className="col-12">
                      <p className="p-3">Right-click on the adjacent image and select 'Save image as' to download image to your desktop.</p>
                      <a
                        className="social-item dl mb my-2 d-noneX"
                        href="#"
                        target="_blank"
                        onClick={_openImage}
                        data-size="1080"
                      >
                        Download 1080 <i className="fas fa-download"></i>
                      </a>
                    </li>
                    <li className="col-12 d-noneX">
                      <a
                        className="social-item dl mb my-2 btnX"
                        href="#"
                        target="_blank"
                        onClick={_openImage}
                        data-size="1280"
                      >
                        Download 1200 <i className="fas fa-download"></i>
                      </a>
                    </li>
                    </>
                      )
                    }
                  {!isAdmin && (!navigator.canShare || window.innerWidth > 1024)
                    ? (
                    <>
                      <li className="col-12 col-lg-6">
                        <a
                          className="social-item fb my-2"
                          href={`https://www.facebook.com/sharer/?u=${siteURI}/share/${donationId}`}
                          target="_blank"
                          onClick={_saveImage}
                        >
                          Facebook <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      {/* <li className="col-12 col-lg-6">
                        <a
                          className="social-item tw my-2"
                          href="https://twitter.com/share"
                          data-url={`${siteURI}/share/${donationId}`}
                          data-related="McGrathFdn"
                          data-text="I donated to make a difference!"
                          data-dnt="true"
                          target="_blank"
                          onClick={_saveImage}
                        >
                          Twitter <i className="fab fa-twitter"></i>
                        </a>
                      </li> */}
                    <li className="col-12 col-lg-6">
                      <a
                        className="social-item li my-2"
                        href={`https://www.linkedin.com/sharing/share-offsite/?url=${siteURI}/share/${donationId}`}
                        data-url={`${siteURI}/share/${donationId}`}
                        data-related="mcgrath-foundation"
                        data-text="I donated to make a difference!"
                        data-dnt="true"
                        target="_blank"
                        onClick={_saveImage}
                      >
                        LinkedIn <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                    <li className="col-12">
                      <a
                        className="social-item dl mb my-2 btn"
                        href={
                          '/?invite=y&did=' + donationId
                        }
                        target="_blank"
                        onClick={_showInvite}
                      >
                        <i className="fas fa-users"></i> Invite friends
                      </a>
                    </li>
                  </>
                    )
                  : null}
                </ul>
                <script
                  async=""
                  src="https://platform.twitter.com/widgets.js"
                  charSet="utf-8"
                ></script>
              </div>
              {!isAdmin
                ? (
              <p className="mt-3 mb-0">
                Please note, Virtual Pink Seats are not redeemable as tickets to
                the NRMA Insurance Pink Test at the SCG 3rd - 7th January 2024.
              </p>
                  )
                : null}
            </div>
          </div>
        </div>
          )
        : null}
    </>
  )
}

if (document.querySelector('#myseat-customiser')) {
  ReactDOM.render(<App />, document.querySelector('#myseat-customiser'))
}
