import { assets } from './_util.js'

// const appEnv = process.env.API_APP_ENV
const myseat = {
  init () {
    if (location.pathname === '/myseat/') {
      this.includes()
    }
  },
  includes () {
    window.addEventListener('load', function () {

    })
    window.addEventListener('DOMContentLoaded', function () {
      assets.add.script('/static/easy.qrcode.js')
      // console.log('LOADED QRCODE.js')
    })
  }
}

myseat.init()
