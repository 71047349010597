// import { loadStripe } from '@stripe/stripe-js'
import { assets, _API_URI } from './_util.js'
import { donate } from './donate.js'

window.addEventListener('DOMContentLoaded', function () {
  assets.add.script('https://js.stripe.com/v3/')
  // console.log('LOADED STRIPE.js')
})
const intentUrl = _API_URI + process.env.API_ENDPOINT_CREATESTRIPEINTENT
const mobileReturnUrl = _API_URI + process.env.API_ENDPOINT_MOBILERETURN
let clientSecret = ''
async function asyncLoadStripe () {
  const stripe = Stripe(process.env.API_STRIPE_KEY, { apiVersion: process.env.API_STRIPE_APIVERSION })
  stripePT.stripe = stripe
  stripePT.stripeInitiated = true
  // console.log('Stripe Loaded')
}

const stripePT = {
  stripe: null,
  stripeInitiated: false,
  stripePaymentInitiated: false,
  clientSecret: null,
  donationId: -1,
  paymentRequest: null,
  paymentRequestButton: null,
  activePayment: '',
  init () {
    if (location.pathname === '/' || location.pathname.indexOf('/sit-with-me') > 0) {
      if (!this.stripeInitiated) {
        asyncLoadStripe()
      }
    }
  },
  paymentInit () {
    if (!this.stripePaymentInitiated) {
      // if (btn === '') { btn = 'payment-request-button' }
      if (this.stripe === null) {
        return
      }
      const paymentRequest = this.stripe.paymentRequest({
        country: 'AU',
        currency: 'aud',
        total: {
          label: 'McGrath Foundation - Pink Test 2024',
          amount: 4000 // 2 seat default
        },
        requestPayerName: true,
        requestPayerEmail: true
      })
      const elements = this.stripe.elements()
      const paymentRequestButton = elements.create('paymentRequestButton', { paymentRequest })
      this.paymentRequest = paymentRequest
      this.paymentRequestButton = paymentRequestButton
      const stripePT = this;

      (async () => {
        // Check the availability of the Payment Request API first.
        const result = await paymentRequest.canMakePayment()
        if (result) {
          // console.log(result)
          stripePT.activePayment = (result.applePay ? 'applePay' : result.googlePay ? 'googlePay' : '')
          const debugMsg = document.getElementById('debug-msg')
          debugMsg.innerHTML += ' | ' + (result.applePay ? 'Apple' : result.googlePay ? 'Google' : 'None (Link)')
          if (stripePT.activePayment !== '') {
            if (stripePT.activePayment === 'applePay') {
              // document.getElementById('payment-request-button').classList.remove('d-none')
              paymentRequestButton.mount('#payment-request-button')
            }

            const pbtn = document.getElementById(stripePT.activePayment)
            pbtn.classList.remove('d-none')
            pbtn.addEventListener('click', (ev) => {
              if (stripePT.stripePaymentInitiated) {
                ev.preventDefault()
                const donateBtn = document.getElementById('donate-submit')
                donateBtn.click()
              }
            })

            stripePT.stripePaymentInitiated = true
            // console.log('STRIPE Payment initiated')
          // pbtn.addEventListener('click', (ev) => {
          //  paymentRequest.show() // Use for custom button
          // })
          }
        } else {
          // document.getElementById(btn).style.display = 'none'
        }
      })()

      const debugMsg = document.getElementById('debug-msg')
      paymentRequest.on('paymentmethod', async (ev) => {
        donate.setProcessingDisplay(
          'ok',
          'Please wait...',
          'We are processing your payment.'
        )

        // Confirm the PaymentIntent without handling potential next actions (yet).
        const { paymentIntent, error: confirmError } = await this.stripe.confirmCardPayment(
          stripePT.clientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false }
        )

        if (confirmError) {
          // Report to the browser that the payment failed, prompting it to re-show the payment
          // interface, or show an error message and close the payment interface.
          ev.complete('fail')
          debugMsg.innerHTML += ' | Payment failed'
          // stripe.processResult('fail', did)
          donate.setProcessingDisplay(
            'error',
            'Oops',
            'Something went wrong with your donation. Payment failed. ')
        } else {
          // Report to the browser that the confirmation was successful, prompting it to close
          // the browser payment method collection interface.
          ev.complete('success')
          // Check if the PaymentIntent requires any actions and if so let Stripe.js handle the flow.
          if (paymentIntent.status === 'requires_action') {
            // Let Stripe.js handle the rest of the payment flow.
            const { error } = await this.stripe.confirmCardPayment(stripePT.clientSecret)
            if (error) {
              // The payment failed -- ask your customer for a new payment method.
              debugMsg.innerHTML += ' | Payment failed' + error
              // stripe.processResult('fail', did)
              donate.setProcessingDisplay(
                'error',
                'Oops',
                'Something went wrong with your donation. ' + error)
            } else {
              // The payment has succeeded.
              // TODO: Mark as IsComplete in database
              stripePT.processResult('success', stripePT.donationId)
            }
          } else {
            // The payment has succeeded.
            // TODO: Mark as IsComplete in database
            stripePT.processResult('success', stripePT.donationId)
          }
        }
      })
    }
  },
  paymentButtonHandler (did, amt, btn) {
    if (btn === '') { return }
    // if (btn === '') { btn = 'payment-request-button' }
    const paymentRequest = this.paymentRequest
    const payButton = document.getElementById(btn)
    const stripePT = this

    payButton.addEventListener('click', function (ev) {
      const amountCents = parseFloat(amt).toFixed(2) * 100 // convert to cents
      paymentRequest.update({ total: { label: 'McGrath Foundation - Pink Test 2024', amount: amountCents } })
      fetch(`${intentUrl}/${did}/${amountCents}`, { credentials: 'same-origin' })
        .then(function (response) {
          if (response.status === 200) {
            response.json().then(function (data) {
              if (data.result === true && data.clientSecret !== '') {
                clientSecret = data.clientSecret

                stripePT.clientSecret = data.clientSecret
                stripePT.donationId = did
                stripePT.paymentRequest.show()
              } else {
                document.getElementById(btn).style.display = 'none'
              }
            })
          }
        })
    })
    return true
  },
  processResult (result, did) {
    if (result === 'success') {
      // Get data
      fetch(mobileReturnUrl + '?id=' + did, { credentials: 'same-origin' })
        .then((response) => {
          if (response.status === 200) {
            response.json().then(function (data) {
              // Set message and redirect
              donate.setProcessingDisplay(
                'ok',
                'Complete!',
                'Thanks for buying your Virtual Pink ' + (data.vs === 1 ? 'Seat' : 'Seats')
              )
              localStorage.setItem('pt24_did', did)
              window.location =
                '/thank-you.html?' +
                new URLSearchParams({
                  did: data.id,
                  amt: data.amt,
                  hasvs: data.hasvs,
                  vs: data.vs,
                  fname: data.fname,
                  lname: data.lname,
                  seatimage: data.personalisation,
                  personalisation: data.personalisation,
                  supporting: '',
                  message: data.message,
                  isvip: data.isvip,
                  iscswm: data.iscswm,
                  friend: data.friend
                })
            })
          }
        }).catch(function (err) {
          donate.setProcessingDisplay(
            'error',
            'Oops!',
            'Something went wrong with your donation. ' + err)
        })
    }
  }
}

export { stripePT }
