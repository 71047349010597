/**
 * PayPal
 * @description Handle PayPal return functionality.
 */
import 'whatwg-fetch'
import { util, _API_URI } from './_util.js'
let token = ''
const tokenUrl = _API_URI + process.env.API_ENDPOINT_GETTOKEN
const ppReturnUrl = _API_URI + process.env.API_ENDPOINT_PAYPALRETURN
const pp = {
  token: '',
  init () {
    if (location.pathname === '/') {
      this.ppCancel()
    }
    if (location.pathname === '/thank-you.html') {
      this.getToken()
    }
  },
  async getToken (refresh) {
    if (localStorage.getItem('pt-token') === null || refresh === true) {
      await fetch(tokenUrl).then(function (response) {
        if (response.status === 200) {
          response.json().then(function (data) {
            token = data.token
            localStorage.setItem('pt-token', data.token)
            pp.token = token
            pp.ppReturn(token)
          })
        }
      })
    } else {
      token = localStorage.getItem('pt-token')
      pp.token = token
      pp.ppReturn(token)
    }
  },
  ppCancel () {
    const cancel = util.getParam('cancel')
    if (cancel === 'true') {
      /*
        TODO: Display message
        */
      localStorage.setItem('pt-token', null)
      pp.getToken()
    }
  },
  ppReturn (tkn) {
    const did = util.getParam('did')
    const amt = util.getParam('amt')
    const guid = util.getParam('guid')
    const completed = util.getParam('completed')
    const payerId = util.getParam('PayerID')
    const paymentId = util.getParam('paymentId')

    if (
      completed === 'true' &&
      (guid !== '' || guid !== undefined) &&
      (amt !== '' || amt !== undefined) &&
      (did !== '' || did !== undefined)
    ) {
      /*
        TODO: Process PayPalReturn (/PayPayReturn)
        */
      fetch(ppReturnUrl, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          Token: tkn,
          PayerId: payerId,
          PaymentId: paymentId,
          Guid: guid,
          DonationId: did
        })
      })
        .then(function (response) {
          if (response.status === 200) {
            response.json().then(function (data) {
              if (data.id !== null && data.id !== undefined) {
                if (data.id === did) {
                  localStorage.setItem('pt24_did', data.id)
                  // window.location = '/thank-you.html?did=' + did + '&amt=' + amt + '&guid=' + guid
                  // window.console.warn("PayPal Complete.");
                }
              }
            })
          }
        })
        .catch(function (err) {
          /*
            TODO: handle gracefully in UI
            */
          window.console.error('Error: ' + err)
        })
    }
  }
}

pp.init()
