/**
 * Donate
 * @description Various methods for the input of the donation form.
 */
import 'whatwg-fetch'
import { util, tooltip, assets, _API_URI, _SHARE_IMAGE_URI } from './_util.js'
import { seatPrice } from './virtual-seats'
import { stripePT } from './stripe-pinktest.js'
import { includes } from 'lodash'

const BreakException = {}
let token = ''

const tokenUrl = _API_URI + process.env.API_ENDPOINT_GETTOKEN
const canDonateUrl = _API_URI + process.env.API_ENDPOINT_CANDONATE
const donateUrl = _API_URI + process.env.API_ENDPOINT_DONATE
const donationId = parseInt(localStorage.getItem('pt24_did')) || 0
const isAustralianUrl = _API_URI + process.env.API_ENDPOINT_ISAUSTRALIAN

const donate = {
  amount: 40,
  virtualSeats: 2,
  token: '',
  donationType: 'virtual-seats', // values are: 'virtual-seats' | 'donation'/null
  init () {
    if (location.pathname === '/') {
      this.getToken()
      this.setLayout()
      this.paymentTypes()
      this.buttons()
      this.submit()
      this.setProcessingDisplay()
      this.includes()

      if (util.getParam('invite') === 'y' && (donationId > 0 || util.getParam('did'))) {
        const didParam = util.getParam('did') ? parseInt(util.getParam('did')) : donationId
        this.invite(didParam)
      }

      document
        .querySelector('.donate-again')
        .addEventListener('click', function () {
          donate.getToken(true)
          document.querySelector('.donate-cancel').click()
        })

      // document.querySelector('.donate-seat').setAttribute('href', '/myseat')
      document.querySelector('.donate-vip').setAttribute('href', '/vip/' + donationId)

      // Direct link to invite
      document.querySelector('.donate-invite').setAttribute('href', '/?invite=y&did=' + donationId)
    }
  },
  async getToken (refresh) {
    if (localStorage.getItem('pt-token') === null || refresh === true) {
      await fetch(tokenUrl + (refresh ? '?refresh=true' : ''), {
        credentials: 'same-origin'
      }).then(function (response) {
        if (response.status === 200) {
          response.json().then(function (data) {
            token = data.token
            localStorage.setItem('pt-token', data.token)
            donate.token = token
            donate.canDonate(token, refresh)
            // donate.finalModal();
          })
        }
      })
    } else {
      token = localStorage.getItem('pt-token')
      donate.token = token
      donate.canDonate(token, refresh)
      // donate.finalModal();
    }
  },
  canDonate (tkn, skipRedonate) {
    if (tkn !== '') {
      fetch(canDonateUrl, {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Token: tkn
        }
      }).then(function (response) {
        if (response.status === 200) {
          response.json().then(function (data) {
            if (!data.result || donationId > 0) {
              if (!skipRedonate && util.getParam('invite') !== 'y') {
                const reDonateModalTrigger = document.getElementById(
                  'redonate-modal-trigger'
                )
                reDonateModalTrigger.click()
              }
            }
          })
        }
      })
    } else {
      window.console.error('No Token Provided.')
    }
  },
  setLayout() {    
    fetch(isAustralianUrl, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //Token: tkn
      }
    }).then(function (response) {
      if (response.status === 200) {
        response.json().then(function (data) {
          //console.log(data)
          if (!data.au) {            
            // Setup fields
            //console.log('alter fields for non-au')
            const mobileRow = document.querySelector(".mobile-container-row")
            const mobileCol = document.querySelector(".mobile-container")
            const countryCol = document.querySelector(".country-container")
            mobileRow.append(mobileCol)
            mobileCol.classList.remove("col-lg-8")            
            mobileRow.classList.remove("d-none")
            countryCol.classList.remove("d-none")
          }/* else {
            console.log('leave fields as-is for au')
          }*/
        })
      }
    })    
  },
  invite (did) {
    // console.log('Show Invite Modal')
    // const inviteTrigger = document.querySelector('a.view-invite')
    // inviteTrigger.click()
    const inviteImg = document.getElementById('invite_image')
    const inviteEls = document.querySelectorAll('.invite-button-area, .invite-instructions')
    const inviteQRTextArea = document.querySelector('.invite-qrtext-area')

    if (navigator.canShare && window.innerWidth < 1024) {
      // console.log('Can Share')
      inviteImg.setAttribute('src', _SHARE_IMAGE_URI + '/' + did + '-0.jpg')
      inviteImg.classList.remove('d-none')
      inviteQRTextArea.classList.add('d-none')
      inviteEls.forEach((el) => { el.classList.remove('d-none') })
      const inviteBtn = document.getElementById('invite_button')
      inviteBtn.setAttribute('href', process.env.SITE_URI + '/share/sit-with-me/' + did + '-0')
      inviteBtn.addEventListener('click', (ev) => {
        ev.preventDefault()
        // Share
        const shareElement = {
          url: inviteBtn.getAttribute('href'),
          title:
                'Come Sit With Me - Help fund McGrath Breast Care Nurses -  NRMA Insurance Pink Test 2024',
          text: 'I’m inviting you to virtually come sit with me at this year’s NRMA Insurance Pink Test in support of the McGrath Foundation and families experiencing breast cancer. Get your Virtual Pink Seat next to mine!'
        }
        navigator.share(shareElement)
      })
    } else {
      window.addEventListener('load', function () {
        if (document.querySelector('#invite_qr_container canvas') === null) {
          util.generateQrCode('?invite=y&did=' + did, '#invite_qr_container')
        }
      })
      inviteImg.classList.add('d-none')
      inviteQRTextArea.classList.remove('d-none')
      inviteEls.forEach((el) => { el.classList.add('d-none') })
    }

    document.querySelector('body').classList.add('noscroll')
    document.querySelector('#modal-sit-with-me').style.display = 'block'
    document.querySelector('#modal-sit-with-me').classList.add('visible')
  },
  finalModal () {
    // const finalModalTrigger = document.getElementById("final-modal-trigger");
    // finalModalTrigger.click();
    document.querySelector('body').classList.add('noscroll')
    document.querySelector('#modal-final-msg').style.display = 'block'
    document.querySelector('#modal-final-msg').classList.add('visible')
  },
  paymentTypes () {
    // console.log(navigator.userAgent)
    const debugMsg = document.getElementById('debug-msg')
    debugMsg.innerHTML = navigator.userAgent
    // const is_safari =
    //   (navigator.userAgent.indexOf('iPhone') > -1 || navigator.userAgent.indexOf('iPad') > -1) &&
    //   navigator.userAgent.indexOf('Chrome') === -1
    // const is_chrome =
    //   (navigator.userAgent.indexOf('iPhone') === -1 && navigator.userAgent.indexOf('iPad') === -1) &&
    //   navigator.userAgent.indexOf('Chrome') > -1
    const canUsePayPal = true
    // const canUseApplePay = (navigator.canShare && window.innerWidth < 1024) && is_safari
    // const canUseGooglePay = !canUseApplePay && ((navigator.canShare && window.innerWidth < 1024) || is_chrome)
    const canUseMobilePay = (navigator.canShare && window.innerWidth <= 1024)

    if (canUsePayPal) {
      /* const payPalBtn = document.getElementById('pp')
      payPalBtn.addEventListener('click', (ev) => {
        console.log('PayPal clicked')
        const active = document.querySelectorAll('.donate-type .btn.active')[0]
        if (active !== undefined) {
          active.classList.remove('active')
        }
        payPalBtn.classList.add('active')
        document.querySelectorAll('.cc-only').forEach(function (item) {
          item.style.display = 'none'
          item.querySelectorAll('input,select').forEach(function (formfield) {
            formfield.removeAttribute('enabled')
            formfield.setAttribute('disabled', '')
            formfield.removeAttribute('invalid')
          })
        })
        const btn = document.getElementById('donate-submit')
        btn.click()
      }) */
    }
    if (canUseMobilePay) {
      // console.log('Mobile Pay')
      const initMobilePayRef = this.initMobilePay
      window.addEventListener('load', function () {
        stripePT.init()
        initMobilePayRef()
        // if (canUseApplePay) {
        //  initMobilePayRef('applePay')
        // } else if (canUseGooglePay) {
        //  initMobilePayRef('googlePay')
        // }
      })
    }
  },
  initMobilePay () {
    // Resize regular payment type button containers
    const creditCardContainer = document.getElementById('creditCard')
    const payPalContainer = document.getElementById('payPal')
    creditCardContainer.classList.remove('col-lg-6')
    payPalContainer.classList.remove('col-lg-6')
    creditCardContainer.classList.add('col-lg-4')
    payPalContainer.classList.add('col-lg-4')

    // Reveal mobile payment type
    /* const paymentTypeContainer = document.getElementById(type)
    // paymentTypeContainer.classList.remove('d-none')
    paymentTypeContainer.addEventListener('click', (ev) => {
      if (stripePT.stripePaymentInitiated) {
        ev.preventDefault()
        const donateBtn = document.getElementById('donate-submit')
        donateBtn.click()
      }
    }) */

    // Check that Stripe has loaded before initialising payment
    let runs = 0
    const toId = setTimeout(checkStripe, 1000) // Wait a sec
    function checkStripe () {
      if (stripePT.stripeInitiated || runs >= 10) { // Max 10 runs
        clearInterval(toId)
        stripePT.paymentInit()
      } else {
        runs++
        // console.log('Waiting for Stripe: ' + runs)
      }
    }
  },
  buttons () {
    document.querySelectorAll('.donate--btn').forEach(function (item) {
      item.addEventListener('click', function () {
        item.querySelector('input[type=radio]').click()
        const active = document.querySelectorAll('.donate--btn.active')[0]
        if (item.classList.contains('other')) {
          document.querySelector('.other-input').style.display = 'block'
          document.querySelector('.other-input input').focus()
          document
            .querySelector('.other-input input')
            .removeAttribute('disabled')
          document
            .querySelector('.other-input input')
            .addEventListener('blur', function () {
              donate.amount =
                document.querySelector('.other-input input').value
            })
        } else {
          document.querySelector('.other-input').style.display = 'none'
          document.querySelector('.other-input input').blur()
          document
            .querySelector('.other-input input')
            .setAttribute('disabled', '')
          donate.amount = item.querySelector('input[type="radio"]').value
        }
        active.classList.remove('active')
        item.classList.add('active')
      })
    })
  },
  submit () {
    const element = document.querySelector('form')
    const errorMessage = document.getElementById('debug-msg')
    element.addEventListener('submit', (event) => {
      event.preventDefault()
      if (document.querySelector('.tooltip')) {
        document.querySelector('.tooltip').remove()
      }
      const form = document.querySelector('form')
      if (!form.checkValidity()) {
        errorMessage.innerHTML = '<i class="fas fa-exclamation-triangle fa-fw"></i> Please fix the errors above and try again. '
        errorMessage.classList.remove('d-none')
        errorMessage.classList.add('d-block')
        // console.log('Not valid!')
        try {
          form.querySelectorAll('input[required]').forEach(function (item, i) {
            // LOOP OTHER VALIDITIES
            if (!item.checkValidity()) {
              item.classList.add('invalid')
              tooltip.error(item, item.validationMessage)
              // console.log('items not valid!')
              throw BreakException
            }
          })
          // CHECK CC
          const cardinput = document.querySelector('.card-input input')
          if (
            !util.validateCC(parseInt(cardinput.getAttribute('input-realvalue')))
          ) {
            cardinput.classList.add('invalid')
            tooltip.error(
              cardinput,
              "This isn't a valid credit card number, please review your input."
            )
            // console.log('cc not valid!')
            throw BreakException
          }
        } catch (e) {
          if (e !== BreakException) throw e
        }
      } else {
        errorMessage.innerHTML = ''
        errorMessage.classList.remove('d-block')
        errorMessage.classList.add('d-none')
        // console.log('Such valid!')
        donate.setProcessingDisplay('reset', '', '')

        const donateModalTrigger = document.getElementById(
          'donate-modal-trigger'
        )
        donateModalTrigger.click()

        let seatimageValue = document.querySelector(
          'form input[type="radio"][name="seatimage"]:checked'
        ).value
        const messageValue = encodeURI(
          document.querySelector('form input#message').value
        )
        const supportingValue = document.querySelector(
          'form input[type="radio"][name="supporting"]:checked'
        )

        if (supportingValue !== null) {
          if (seatimageValue > supportingValue) {
            seatimageValue = 0
          }
        }

        donate.virtualSeats = parseInt(
          document.querySelector('.seats--quantity-input input').value
        )
        donate.amount = seatPrice * donate.virtualSeats

        const personalisationValue = donate.virtualSeats >= 5 ? 5 : donate.virtualSeats - 1
        /* let personalisationValue = 0
        if (donate.virtualSeats === 2) {
          personalisationValue = 1
        } else if (donate.virtualSeats === 3) {
          personalisationValue = 2
        } else if (donate.virtualSeats === 4) {
          personalisationValue = 3
        } else if (donate.virtualSeats > 2) {
          personalisationValue = 4
        } */

        // OK
        fetch(donateUrl, {
          method: 'POST',
          credentials: 'same-origin',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            Token: donate.token,
            Amount: donate.amount,
            PaymentMethod: document.querySelector(
              '.donate-type.row .donate.active h3'
            ).innerHTML,
            FirstName: encodeURIComponent(
              document.getElementById('first_name').value
            ),
            LastName: encodeURIComponent(
              document.getElementById('last_name').value
            ),
            EmailAddress: document.getElementById('email').value,
            Postcode: document.getElementById('postcode').value,
            Country: document.getElementById('country').value,
            Mobile: document.getElementById('mobile').value,
            Comments: document.getElementById('message').value,
            AnonymousDonation:
              document.getElementById('check-anonymous').checked,
            /* CreditCardName: document.getElementById('name').value, */
            CreditCardNumber: document
              .getElementById('cardnumber')
              .value.replace(/\s/g, ''),
            CreditCardExpiryMonth:
              document.getElementById('cc_exp_month').value,
            CreditCardExpiryYear: document.getElementById('cc_exp_year').value,
            CreditCardSecurityCode: document.getElementById('cc_cvv').value,
            Personalisation: personalisationValue.toString() || '0',
            Supporting: supportingValue ? supportingValue.value : '',
            HasVirtualSeats: donate.donationType === 'virtual-seats',
            VirtualSeats:
              donate.donationType === 'virtual-seats' ? donate.virtualSeats : 0,
            VirtualSeatsFirstName: encodeURIComponent(
              document.getElementById('first_name').value.trim()
            ),
            VirtualSeatsLastName: encodeURIComponent(
              document.getElementById('last_name').value.trim()
            )
          })
        })
          .then(function (response) {
            if (response.status === 200) {
              response.json().then(function (data) {
                if (data.id !== null && data.id !== undefined) {
                  if (data.ppLink !== null && data.ppLink !== undefined) {
                    donate.setProcessingDisplay(
                      'ok',
                      'Going to PayPal...',
                      "We're taking you to PayPal to complete your donation."
                    )
                    window.location = data.ppLink
                  } else if (data.mobile !== null && data.mobile !== undefined) {
                    const mobilePayment = data.mobile === 'googlePay' ? 'Google Pay' : data.mobile === 'applePay' ? 'Apple Pay' : ''
                    donate.setProcessingDisplay(
                      'ok',
                      'Loading ' + mobilePayment + '...',
                      "We're loading " + mobilePayment + ' to complete your donation.'
                    )
                    const isReady = stripePT.paymentButtonHandler(data.id, data.amt, 'payment-request-button')
                    if (isReady) {
                      const payButton = document.getElementById('payment-request-button')
                      payButton.click()

                      if (stripePT.activePayment === 'applePay') {
                        donate.setProcessingDisplay(
                          'ok',
                          'Thank you...',
                          'Please tap the button below to checkout using ' + mobilePayment + '.'
                        )
                        // Move Apple Pay button to modal!
                        payButton.classList.remove('d-none')
                        // payButton.mount('#payment-request-button')
                        // stripePT.paymentRequest.show()
                        document.getElementById('processing').appendChild(payButton)
                      }
                    }
                  } else {
                    donate.setProcessingDisplay(
                      'ok',
                      'Complete!',
                      'Thanks for buying your Virtual Pink ' + (data.vs === 1 ? 'Seat' : 'Seats')
                    )
                    localStorage.setItem('pt24_did', data.id)
                    localStorage.setItem('pt24_vs', data.vs)
                    window.location =
                      '/thank-you.html?' +
                      new URLSearchParams({
                        did: data.id,
                        amt: data.amt,
                        hasvs: data.hasvs,
                        vs: data.vs,
                        fname: data.fname,
                        lname: '', // data.lname,
                        seatimage: seatimageValue,
                        personalisation: personalisationValue,
                        supporting:
                          supportingValue !== null ? supportingValue.value : '',
                        message: messageValue,
                        isvip: data.isvip
                      })
                  }
                } else {
                  donate.setProcessingDisplay(
                    'error',
                    'Oops',
                    'Something went wrong with your donation. ' + data.message
                  )
                  window.console.warn(data.debug)
                }
              })
            } else {
              response.json().then(function (data) {
                donate.setProcessingDisplay(
                  'error',
                  'Oops',
                  'Something went wrong with your donation. ' + data.message
                )
                window.console.warn(data.debug)
              })
            }
          })
          .catch(function (err) {
            donate.setProcessingDisplay(
              'error',
              'Uh-oh!',
              'There was a problem processing your donation.'
            )
            window.console.error('Error: ' + err)
          })
      }
    })
  },
  setProcessingDisplay (action, msgTitle, msg, icon) {
    const donateModalTitle = document.querySelector(
      '.modal-content.processing h2'
    )
    const donateModalMessage = document.getElementById('processing')
    const processingSpinner = document.querySelector(
      '.modal-content.processing i.fas'
    )
    if (action === 'reset') {
      if (processingSpinner.classList.contains('fa-exclamation-triangle')) {
        donateModalTitle.innerHTML = 'Processing your donation .... '
        donateModalMessage.innerHTML =
          'Please wait a moment while we complete your donation.'
        processingSpinner.classList.remove('fa-exclamation-triangle')
        processingSpinner.classList.add('fa-circle-notch', 'fa-spin')
      }
    } else if (action === 'error') {
      donateModalTitle.innerHTML = msgTitle
      donateModalMessage.innerHTML = msg
      processingSpinner.classList.remove('fa-circle-notch', 'fa-spin')
      processingSpinner.classList.add('fa-exclamation-triangle')
    } else if (action === 'ok') {
      donateModalTitle.innerHTML = msgTitle
      donateModalMessage.innerHTML = msg
      processingSpinner.classList.remove('fa-circle-notch', 'fa-spin')
      processingSpinner.classList.add('fa-circle-check')
    }
  },
  setType (type) {
    donate.donationType = type
  },
  setVirtualSeats (vs) {
    donate.virtualSeats = vs
  },
  includes () {
    window.addEventListener('DOMContentLoaded', function () {
      assets.add.script('/static/easy.qrcode.js')
    })
  }
}

donate.init()

export { donate }
