import Tick from '@pqina/flip'
import { useState } from 'react'
import { _API_URI } from './_util.js'

const React = require('react')
const ReactDOM = require('react-dom')

export default class Flip extends React.Component {
  constructor (props) {
    super(props)
    this._tickRef = React.createRef()
  }

  componentDidMount () {
    this._tickInstance = Tick.DOM.create(this._tickRef.current, {
      value: this.props.value
    })
  }

  componentDidUpdate () {
    if (!this._tickInstance) return
    this._tickInstance.value = this.props.value
  }

  componentWillUnmount () {
    if (!this._tickInstance) return
    Tick.DOM.destroy(this._tickRef.current)
  }

  render () {
    return (
      <div ref={this._tickRef} className="tick">
        <div
          data-repeat="true"
          data-transform="round -> pad('      ') -> split -> delay(rtl, 100, 500)"
          aria-hidden="true"
        >
          <span data-view="flip">Tick</span>
        </div>
      </div>
    )
  }
}

function App (props) {
  const [totalAUS, setTotalAUS] = React.useState(0)
  const [totalENG, setTotalENG] = React.useState(0)
  const [posts, setPosts] = React.useState([])

  React.useEffect(() => {
    fetch(_API_URI + process.env.API_ENDPOINT_STATICDONATION)
      .then((response) => response.json())
      .then((data) => {
        setTotalAUS(data.totalAUS)
        setTotalENG(data.totalENG)
        setPosts(data.latest)
      })
    setInterval(() => {
      if (document.hasFocus()) {
        fetch(_API_URI + process.env.API_ENDPOINT_STATICDONATION)
          .then((response) => response.json())
          .then((data) => {
            setTotalAUS(data.totalAUS)
            setTotalENG(data.totalENG)
            setPosts(data.latest)
          })
      }
    }, 10000)
  }, [])

  return (
    <div>
      <div className="mt-2">
        <div className="text-center">
          <h2>Messages of Support</h2>
        </div>
        {posts.map((post, index) => {
          return (
            <div className="row" key={index}>
              <div className="emblem">
                <img
                  src={
                    '/img/emblems/' +
                    (post.TeamSupporting
                      ? post.TeamSupporting
                      : 'virtual-seat') +
                    '.svg'
                  }
                  className="inline-svg"
                />
              </div>
              <div className="information">
                <h3>
                  <span>{post.Name}</span>
                  <span className="text-purple">
                    &nbsp;x {post.VirtualSeats} {post.VirtualSeats === 1 ? "Seat" : "Seats"}
                  </span>
                </h3>
                <h4>{post.Message}</h4>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

if (document.querySelector('#flipper')) {
  ReactDOM.render(<App />, document.querySelector('#flipper'))
}
