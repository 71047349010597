const initialSeats = 2
const seatPrice = 20

const virtual_seats = {
  init () {
    this.quantity()
    this.change()
    this.listener()
    this.radio()
  },
  quantity () {
    const elIncrease = document.querySelector('[data-seat-increase]')
    const elDecrease = document.querySelector('[data-seat-subtract]')
    const elInput = document.querySelector('.seats--quantity-input input')
    elIncrease.addEventListener('click', function () {
      if (parseInt(elInput.value) < 99999) {
        elInput.value = parseInt(elInput.value) + 1
        virtual_seats.change()
      }
    })
    elDecrease.addEventListener('click', function () {
      if (parseInt(elInput.value) - 1 > 0) {
        elInput.value = parseInt(elInput.value) - 1
        virtual_seats.change()
      }
    })
  },
  listener () {
    const elInput = document.querySelector('.seats--quantity-input input')
    elInput.addEventListener('change', virtual_seats.change, false)
    elInput.addEventListener('blur', virtual_seats.change, false)
  },
  change () {
    const elInput = document.querySelector('.seats--quantity-input input')
    document.querySelector('.seats--quantity-price').innerHTML =
      '= $' + (parseInt(elInput.value) * seatPrice).toString()
    virtual_seats.radio()
    if (parseInt(elInput.value) < 1) elInput.value = 1
    if (parseInt(elInput.value) > 99999) elInput.value = 99999
  },
  radio () {
    let index = 0
    const elInput = document.querySelector('.seats--quantity-input input')
    const elRadio = document.querySelectorAll('.seats--radio-input')

    elRadio.forEach((v, i) => {
      const min_seats = v.getAttribute('data-minimum-seats')
      if (parseInt(elInput.value) >= parseInt(min_seats)) {
        index++
        v.classList.remove('disabled')
        v.querySelector('input').removeAttribute('disabled')
        if (parseInt(elInput.value) >= 3) {
          const mval = parseInt(elInput.value) >= 5 ? 5 : parseInt(elInput.value)
          const img = document.querySelector('img.multi-seat')
          if (img !== null) {
            img.setAttribute('src', '/img/radio/radio--multi' + mval + '.webp')
          }
        }
      } else {
        v.classList.add('disabled')
        v.querySelector('input').setAttribute('disabled', true)
      }
      v.querySelector('input[type=radio]').removeAttribute('checked')
    })

    setTimeout(() => {
      // console.log(index)
      const selected = (index >= 3) ? (parseInt(elInput.value) > 5 ? 3 : 2) : index - 1
      elRadio[selected]
        .querySelector('input[type=radio]')
        .setAttribute('checked', true)
    }, 500)
  }
}
if (location.pathname === '/') {
  document.addEventListener('DOMContentLoaded', function () {
    virtual_seats.init()
  }, false)
}

export { seatPrice }
