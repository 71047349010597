import { util } from './_util.js'

const init = {
  init () {
    this.postLoad()
    this.inlineSVG()
  },
  postLoad () {
    window.addEventListener('load', function () {
      document
        .querySelectorAll('[data-type=post-load]')
        .forEach(function (item) {
          const link = document.createElement('link')
          link.setAttribute('rel', 'stylesheet')
          link.setAttribute('href', item.getAttribute('data-href'))
          item.parentNode.insertBefore(link, item)
          item.parentNode.removeChild(item)
        })
    })
  },
  inlineSVG () {
    window.addEventListener('load', function () {
      document.querySelectorAll('img.inline-svg').forEach(function (item) {
        util.resolveSVG(item)
      })
    })
  }
}

init.init()
