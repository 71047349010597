import { include } from './_util.js'

// import { corporate_partners, partner_slug } from './corporate'
import { sitwithme_slug } from './sit-with-me'

function dataclick () {
  document.querySelectorAll('[data-toggle=click]').forEach(function (item) {
    item.addEventListener('click', function () {
      document.querySelector(item.getAttribute('data-element')).click()
    })
  })
}

function header () {
  hamburger()
  emblem()
  backButton()
  mySeat()
}

function backButton () {
  if (
    (location.pathname === '/experience-more.html' || location.pathname === '/impact.html' || location.pathname === '/myseat/') &&
    document.querySelector('.btn-back')
  ) {
    document.querySelector('.btn-back').classList.add('d-lg-flex')
  } else if (location.pathname === '/') {
    if (document.querySelector('.btn-expe')) {
      document.querySelector('.btn-expe').classList.add('d-lg-flex')
    }
  }
}

function mySeat () {
  const donationId = parseInt(localStorage.getItem('pt24_did')) || 0
  if (donationId > 0) {
    const btnSeatMenu = document.querySelector('.btn-seat-menu')
    if (btnSeatMenu !== null) {
      btnSeatMenu.classList.remove('d-none')
      if (location.pathname === '/') {
        document.querySelector('.btn-seat').classList.remove('d-none')
      }
    }
  }
}

function hamburger () {
  const button = document.querySelector("header [data-toggle='hamburger']")
  const menu = document.querySelector('section.hamburger')
  if (button !== undefined) {
    button.addEventListener('click', () => {
      if (menu.classList.contains('hamburger__active')) {
        button.querySelector('i').classList.add('fa-bars')
        button.querySelector('i').classList.remove('fa-times')
        menu.classList.remove('hamburger__active')
      } else {
        button.querySelector('i').classList.remove('fa-bars')
        button.querySelector('i').classList.add('fa-times')
        menu.classList.add('hamburger__active')
      }
    })
  }
}

function emblem () {
  if (
    location.pathname.startsWith('/partner/') &&
    partner_slug !== undefined &&
    partner_slug !== ''
  ) {
    const seriesImage = document.querySelector('header img.series')
    if (seriesImage && partner_slug) {
      seriesImage.setAttribute(
        'src',
        corporate_partners[partner_slug].HeaderLogo
      )
    }
  }
}

const includes = {
  init () {
    this.onload()
  },
  onload () {
    window.addEventListener('load', function () {
      include.get('header', header)
      include.get('terms')
      include.get('privacy')
      include.get('footer', dataclick)
      include.get('lockup')
    })
  }
}

includes.init()
