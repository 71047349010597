/**
 * Goal
 * @description Build out different methods we show for the goal!
 */
import 'whatwg-fetch'
import { util, _API_URI } from './_util.js'
const split = 15
const totalGoal = 6440000
const totalVsGoal = 322000

let currentAmt = 0
let currentVsAmt = 0
let latest = []

let totalAUS = 0
let totalENG = 0

let feedUrl = _API_URI + process.env.API_ENDPOINT_STATICDONATION

if (location.pathname !== '/' && location.pathname !== '/myseat/' && location.pathname !== '/impact.html' && location.pathname !== '/experience-more.html') {
  feedUrl = _API_URI + process.env.API_ENDPOINT_LATESTDONATION
}

fetch(feedUrl, {})
  .then(function (response) {
    return response.json()
  })
  .then(function (json) {
    const waitSVGLoad = setInterval(function () {
      clearTimeout(waitSVGLoad)
      currentAmt = json.totalGoal
      currentVsAmt = json.totalVS
      totalAUS = json.totalAUS
      totalENG = json.totalENG
      latest = json.latest
      goal.init()
    }, 250)
  })

function format (n, l = false) {
  const base = Math.floor(Math.log(Math.abs(n)) / Math.log(1000))
  let suffix = ['K', 'M', 'B'][base - 1]
  if (l) {
    suffix = [' THOUSAND', ' MILLION', ' BILLION'][base - 1]
  }
  return suffix
    ? String(n / Math.pow(1000, base)).substring(0, 3) + suffix
    : '' + n
}
const goal = {
  init () {
    this.titles()
    if (location.pathname === '/') {
      this.bar()
      // this.engvsaus();
      this.icons()
      //this.latest()
    }
  },
  titles () {
    if (document.querySelector('#vs-total')) {
      document.querySelector('#vs-total').textContent = util.formatNumber(
        currentVsAmt,
        true
      )
    }
    if (document.querySelector('#donate-total')) {
      document.querySelector('#donate-total').textContent =
        util.formatNumber(currentAmt, false) + ' Raised'
    }
  },
  bar () {
    const perc = currentVsAmt / totalVsGoal // (currentAmt / totalGoal)
    const bar = new ProgressBar.Path('#meter--path', {
      easing: 'easeInOut',
      duration: 2000,
      step: function (state, path, attachment) {
        if (path.value() >= 0.1) {
          document.querySelector('#meter--prog--20').classList.add('meter--on')
        }
        if (path.value() >= 0.3) {
          document.querySelector('#meter--prog--40').classList.add('meter--on')
        }
        if (path.value() >= 0.7) {
          document.querySelector('#meter--prog--80').classList.add('meter--on')
        }
        if (path.value() >= 1) {
          document
            .querySelector('#meter--prog--100')
            .classList.add('meter--on')
        }
      }
    })
    bar.set(0)

    if (perc < 0.025) {
      bar.animate(0.01) // Min 1% visual
    } else if (perc < 1) {
      bar.animate(perc)
    } else {
      bar.animate(1) // Goal achieved
    }
  },
  engvsaus () {
    const bar_aus = new ProgressBar.Path('#inner-meter--path-aus', {
      easing: 'easeInOut',
      duration: 2000
    })
    const bar_eng = new ProgressBar.Path('#inner-meter--path-eng', {
      easing: 'easeInOut',
      duration: 2000
    })
    bar_aus.set(0)
    bar_eng.set(0)

    const totalAUSperc = (100 / (totalAUS + totalENG)) * totalAUS
    const totalENGperc = (100 / (totalAUS + totalENG)) * totalENG

    const perc = currentVsAmt / totalVsGoal

    const vsAUSperc = perc * (totalAUSperc / 100)
    const vsENGperc = perc * (totalENGperc / 100)

    bar_aus.animate(vsAUSperc)
    bar_eng.animate(vsAUSperc + vsENGperc)
  },
  icons () {
    function inline () {
      document.querySelectorAll('img.inline-svg').forEach(function (item) {
        util.resolveSVG(item)
      })
    }
  },
  latest () {
    if ((latest !== null || latest !== undefined) && latest.length > 0) {
      if (document.querySelector('anchor#donations')) {
        document.querySelector('anchor#donations').innerHTML = ''
        latest.forEach(function (user) {
          const content = document.createElement('div')
          let tier = 1
          if (user.amount >= 250) tier = 2
          if (user.amount >= 500) tier = 3
          // if (user.amount >= 500) tier = 4
          content.setAttribute('class', 'row')
          let information = ''
          information += '<div class="information">'
          if (user.hasVirtualSeats === true) {
            content.innerHTML +=
              '<div class="emblem"><img src="/img/emblems/virtual-seat.svg" class="inline-svg"></div>'
            const seatCountTxt = user.virtualSeats === 1 ? 'Seat' : 'Seats'
            information +=
              '<h3><span>' +
              user.name +
              '</span> ' + //<img src="img/icons/virtual-seat-icon.svg"> x ' +
              user.virtualSeats +
              ' ' +
              seatCountTxt +
              '</h3>'
          } else {
            content.innerHTML +=
              '<div class="emblem"><img src="/img/emblems/tier' +
              tier +
              '.svg" class="inline-svg"></div>'
            information +=
              '<h3><span>' +
              user.name +
              '</span> donated $' +
              user.amount +
              '</h3>'
          }
          information += '<h4>' + user.message + '</h4>'
          content.innerHTML += information
          content.innerHTML += '</div>'
          document.querySelector('anchor#donations').append(content)
        })
      }
    }
  }
}

goal.init()
