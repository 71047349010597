/**
 * Credit Card
 * @description Builds out different methods that we need for cc input.
 */

import { util, tooltip } from './_util.js'

export const cc = {
  init () {
    if (location.pathname === '/') {
      this.watchIcon()
      this.validate()
    }
  },
  validate () {

    const cardExpiryMM = document.getElementById('cc_exp_month')
    const cardExpiryYY = document.getElementById('cc_exp_year')
    let $cc = this;
    cardExpiryMM.addEventListener('focusout', function () {
      if (!$cc.expiryValid(cardExpiryMM, cardExpiryYY)) { return }
    })
    cardExpiryYY.addEventListener('focusout', function () {
      if (!$cc.expiryValid(cardExpiryMM, cardExpiryYY)) { return }
    })    

    const cardinput = document.querySelector('.card-input input')
    cardinput.addEventListener('focusin', function () {
      this.setAttribute('type', 'tel')
      // this.value = this.getAttribute('input-realvalue')
    })
    cardinput.addEventListener('focusout', function () {
      this.setAttribute('type', 'text')
      this.setAttribute('input-realvalue', this.value.replace(/\s/g, ''))
      this.classList.add('number')
      try {
        this.value = this.value
          .toString()
          .replace(/[^\dA-Z]/g, '')
          .replace(/(.{4})/g, '$1 ')
          .trim()
        this.classList.remove('invalid')
      } catch (err) {
        this.classList.add('invalid')
        return
      }
      if (!util.validateCC(parseInt(this.getAttribute('input-realvalue')))) {
        this.classList.add('invalid')
        tooltip.error(
          this,
          "This isn't a valid credit card number, please review your input."
        )
        return
      }
      this.classList.remove('invalid')
    })
  },
  expiryValid (mm, yy) {
    const d = new Date()
    let theYear = Number.parseInt(d.getFullYear().toString().slice(-2))
    if (mm.value === '' && yy.value === '') { return false; }
    tooltip.clear(mm)
    tooltip.clear(yy)
    if (
      (Number.isNaN(mm.value) || (parseInt(mm.value) > 12 || parseInt(mm.value) <= 0)) 
      ||
      (Number.isNaN(yy.value) || (parseInt(yy.value) > theYear + 10 || parseInt(yy.value) < theYear))
    ) {
      mm.classList.add('invalid')
      yy.classList.add('invalid')
      tooltip.error(
        yy,
        "Card expiry isn't valid. Please review your input."
      )
      return false
    }
    mm.classList.remove('invalid')
    yy.classList.remove('invalid')
    return true
  },
  watchIcon () {
    function getCT (c) {
      return c.match(new RegExp('^4')) != null ||
        c.match(new RegExp('^(4026|417500|4508|4844|491(3|7))')) != null
        ? 'cc-visa'
        : c.match(new RegExp('^5')) != null
          ? 'cc-mastercard'
          : c.match(new RegExp('^3[47]')) != null
            ? 'cc-amex'
            : c.match(
              new RegExp(
                '^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)'
              )
            ) !=
          null
              ? 'cc-discover'
              : c.match(new RegExp('^36')) != null ||
          c.match(new RegExp('^30[0-5]')) != null
                ? 'cc-diners-club'
                : c.match(new RegExp('^35(2[89]|[3-8][0-9])')) != null
                  ? 'cc-jcb'
                  : null
    }
    'keyup keydown change'.split(' ').forEach(function (e) {
      document
        .querySelector('input#cardnumber')
        .addEventListener(e, function () {
          const cardType = getCT(this.value)
          const cardIcon = document.querySelector('.card-icon i')
          cardIcon.className = ''
          if (cardType != null) {
            cardIcon.className = 'fab fa-' + cardType
          } else {
            cardIcon.className = 'far fa-credit-card-blank'
          }
        })
    })
  }
}

cc.init()
