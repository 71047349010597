/**
 * PINKTEST 2022-4.
 * @description: Frontend for the PinkTest 2022-24 site.
 * @author: Jake Howell, Daniel Steinhauser
 */

import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

// import "./_ie11";
import './_polyfill'
import './_util'

import 'whatwg-fetch'

import './includes'
import './init'
import './toggle'
import './input'
import './thankyou'
import './goal'
import './virtual-seats'
import './creditcard'
import './paypal'
import './customiser'
import './customiser-sit-with-me'
// import './customiser-corporate'
import './customiser-myseat'
import './myseat'
import './flipper'
import './hamburger'
import './experience'
import './impact'

const regeneratorRuntime = require('regenerator-runtime')
require('@babel/polyfill')
// import './donate'
