import { include, assets } from './_util.js'
import Glide from '@glidejs/glide'

const impact = {
  init () {
    if (location.pathname === '/impact.html') {
      this.includes()
    }
  },
  includes () {
    window.addEventListener('load', function () {
      include.get('impact', features)
      // include.get("lockup");
      assets.add.script('/static/stackla.min.js')
    })
  },
  getUrlParameter (sParam) {
    let sPageURL, sURLVariables, sParameterName, i
    sPageURL = decodeURIComponent(window.location.search.substring(1))
    sURLVariables = sPageURL.split('&')

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=')

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : sParameterName[1]
      }
    }
  }
}

impact.init()

function features () {
  include.get('moments', null)
  include.get('social-wall', null)
  // var elementFeat = document.getElementById('feat-party')
  // elementFeat.classList.remove('d-none')
}
