import { include, assets } from './_util.js'
import Glide from '@glidejs/glide'

const experience = {
  init () {
    if (location.pathname === '/experience-more.html') {
      this.includes()
    }
  },
  includes () {
    window.addEventListener('load', function () {
      include.get('experience', features)
      include.get('carousel', carousel)
      include.get('moments', null)
      include.get('social-wall', null)
      // include.get("lockup");
      assets.add.script('/static/stackla.min.js')
    })
  },
  getUrlParameter (sParam) {
    let sPageURL, sURLVariables, sParameterName, i
    sPageURL = decodeURIComponent(window.location.search.substring(1))
    sURLVariables = sPageURL.split('&')

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=')

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : sParameterName[1]
      }
    }
  }
}

experience.init()

function features () {
  //console.log("features")
  let seats = 0;
  /*let isCSWM = (experience.getUrlParameter('iscswm') !== null && experience.getUrlParameter('iscswm') === 'true') || false;
  if (isCSWM) {
    eleFeat_Seat.querySelector("h2").innerHTML = "Buy More";
  }*/
  const features = document.querySelectorAll('.feature-item')

  if (localStorage.getItem('pt24_vs') !== null) {
    seats = parseInt(localStorage.getItem('pt24_vs'))
  }
  
  if (seats > 0) {
    if (seats === 1) {
      // One purchased: Invite, Auction, PSD
      const aInvite = document.getElementById('exp-invite')
      aInvite.setAttribute('href', '/?invite=y&did=' + localStorage.getItem('pt24_did'))

      //const eleFeat_Invite = document.getElementById('feat-invite')
      const eleFeat_Auction = document.getElementById('feat-auction')
      const eleFeat_PSD = document.getElementById('feat-psd')
      const eleFeat_Merch = document.getElementById('feat-merch')
      eleFeat_Auction.classList.remove('d-none')
      eleFeat_PSD.classList.remove('d-none')
      eleFeat_Merch.classList.remove('d-none')  
    } else {
      // Multi purchased: Auction, PSD, Merch
      const eleFeat_Auction = document.getElementById('feat-auction')
      const eleFeat_PSD = document.getElementById('feat-psd')
      const eleFeat_Merch = document.getElementById('feat-merch')
      eleFeat_Auction.classList.remove('d-none')
      eleFeat_PSD.classList.remove('d-none')
      eleFeat_Merch.classList.remove('d-none')
    }
  } else {
    // None purchased: Seat, Donate, PSD
    const eleFeat_Seat = document.getElementById('feat-seat')
    const eleFeat_Auction = document.getElementById('feat-auction')
    const eleFeat_PSD = document.getElementById('feat-psd')
    eleFeat_Seat.classList.remove('d-none')
    eleFeat_Auction.classList.remove('d-none')
    eleFeat_PSD.classList.remove('d-none')
  }
}

function carousel () {
  new Glide('.carousel', {
    gap: 0,
    hoverpause: true,
    autoplay: 5000
  }).mount()
}
