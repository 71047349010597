import { util, include, assets } from './_util.js'

const saveShareUrl = process.env.API_ENDPOINT_DONATE
const appEnv = process.env.API_APP_ENV
const thankyou = {
  init () {
    if (location.pathname === '/thank-you.html') {
      this.includes()
      this.amount()
      if (appEnv === 'live') {
        console.log('DonationTracking')
        this.donationTracking()
      }
    }
  },
  includes () {
    window.addEventListener('load', function () {
      if (parseInt(util.getParam('vs')) === 1) {
        include.get('invite-mates', inviteMates)
      }      
      include.get('experience', features)
      //include.get('moments', null)
      include.get('social-wall', null)
      assets.add.script('/static/stackla.min.js')
    })
    window.addEventListener('DOMContentLoaded', function () {
      assets.add.script('/static/easy.qrcode.js')
      // console.log('LOADED QRCODE.js')
    })
  },
  amount () {
    const purchaseAmount = document.querySelector('[data-purchase-amount]')
    if (purchaseAmount) {
      purchaseAmount.innerHTML = util.getParam('vs')
    }
    if (
      parseInt(util.getParam('vs')) === 1 &&
      document.querySelector('[data-purchase-text]')
    ) {
      const el = document.querySelector('[data-purchase-text]')
      el.innerText = 'Virtual Pink Seat'
      //const el2 = document.querySelector('[data-display-text]')
      //el2.innerText = 'Virtual Pink Seat'
    }
  },
  donationTracking () {
    /* GA E-commerce */
    const amt = util.getParam('amt')
    const did = util.getParam('did')
    const hasvs = util.getParam('hasvs')
    const vs = util.getParam('vs')
    const donationType = 'Virtual Seat'
    const tracked = localStorage.getItem('pt24_tracked')
    console.log(tracked)
    if (tracked === null || tracked !== did) {
      window.dataLayer = window.dataLayer || []

      if (did != null && amt != null) {

        /*window.dataLayer.push({
          donationType: 'Pink Test 2024 ' + donationType
        })*/
        //window.dataLayer.push({ donationId: did })
        //window.dataLayer.push({ donationValue: amt })

        /* Google Analytics event */
        //window.dataLayer.push({ event: 'donation-complete' })

        /* Google Analytics ecommerce event */
        /*window.dataLayer.push({
          ecommerce: {
            purchase: {
              actionField: {
                id: did,
                affiliation: 'Pink Test website',
                revenue: amt,
                tax: 0,
                shipping: 0
              },
              products: [
                {
                  name: 'Pink Test 2024 ' + donationType,
                  id: 'PT2024_VS',
                  category: donationType,
                  price: amt / vs,
                  quantity: vs
                }
              ]
            }
          }
        })*/

        gtag("event", "purchase", {
          transaction_id: did,
          value: amt,
          tax: 0,
          shipping: 0,
          currency: "AUD",
          items: [
            {
             item_id: "PT2024_VPS",
             item_name: "Pink Test 2024 " + donationType,
             affiliation: "Pink Test",
             price: amt / vs,
             quantity: vs
            }]
        })

        localStorage.setItem('pt24_tracked', did)
        console.log(
          'DonationTracking: ' + donationType + ', $' + amt + ', vs: ' + vs
        )
      }
    }
  }
}

function features () {
  let seats = 0;
  const features = document.querySelectorAll('.feature-item')

  seats = parseInt(util.getParam('vs'))
  //console.log("features, " + seats)

  if (seats > 0) {
    if (seats === 1) {
      // One purchased: Invite, Auction, PSD
      const aInvite = document.getElementById('exp-invite')
      aInvite.setAttribute('href', '/?invite=y&did=' + localStorage.getItem('pt24_did'))

      //const eleFeat_Invite = document.getElementById('feat-invite')
      const eleFeat_Auction = document.getElementById('feat-auction')
      const eleFeat_PSD = document.getElementById('feat-psd')
      const eleFeat_Merch = document.getElementById('feat-merch')
      //eleFeat_Invite.classList.remove('d-none')
      eleFeat_Auction.classList.remove('d-none')
      eleFeat_PSD.classList.remove('d-none')
      eleFeat_Merch.classList.remove('d-none')  
    } else {
      // Multi purchased: Auction, PSD, Merch
      const eleFeat_Seat = document.getElementById('feat-seat')
      const eleFeat_Auction = document.getElementById('feat-auction')
      const eleFeat_PSD = document.getElementById('feat-psd')
      //const eleFeat_Invite = document.getElementById('feat-invite')
      //const eleFeat_Merch = document.getElementById('feat-merch')
      eleFeat_Seat.querySelector("a.btn").innerHTML = "Buy More"
      eleFeat_Seat.classList.remove('d-none')
      eleFeat_Auction.classList.remove('d-none')
      eleFeat_PSD.classList.remove('d-none')
      //eleFeat_Invite.classList.remove('d-none')
      //eleFeat_Merch.classList.remove('d-none')  
    }
  } else {
    // None purchased: Seat, Donate, PSD
    const eleFeat_Seat = document.getElementById('feat-seat')
    const eleFeat_Auction = document.getElementById('feat-auction')
    const eleFeat_PSD = document.getElementById('feat-psd')
    eleFeat_Seat.classList.remove('d-none')
    eleFeat_Auction.classList.remove('d-none')
    eleFeat_PSD.classList.remove('d-none')  
  }
}

function inviteMates () {
  const imContainer = document.querySelector(".invite-mates-container")
  const did = parseInt(util.getParam('did'))  
  if (did > 0) {
    const imCTA = document.querySelector(".invite-feature-cta")
    if (imCTA !== null) {
      imCTA.setAttribute('href', '/?invite=y&did=' + did)
      imCTA.addEventListener('click', (e) => { 
        e.preventDefault()

        // Trigger saving of friend invite image to display
        const finishBtn = document.querySelector(".social-share.virtual-seats .done-personalising")
        if (finishBtn !== null) { 
          finishBtn.click()
        }
        // Show invite modal
        const inviteBtn = document.querySelector(".invite-friends-btn")
        if (inviteBtn !== null) { 
          inviteBtn.click()
        }
        // Return to previous personalisation state
        const backBtn = document.querySelector(".social-share.virtual-seats .continue-personalising")
        if (backBtn !== null) {
          backBtn.click()
        }
      })
    }
  } else {
    imContainer.classList.add("d-none")
  }    
}

thankyou.init()
