import { assets } from './_util.js'

export const sitwithmeID1 = Number(getID(location.pathname, 1))
export let sitwithmeID2 = Number(getID(location.pathname, 2))

const sitwithme = {
  isReady: false,
  init () {
    if (location.pathname.startsWith('/sit-with-me/') && sitwithmeID1) {
      if (sitwithmeID1 > 0) {
        // console.log('SWM ID1: ' + sitwithmeID1)
        // console.log('SWM ID2: ' + sitwithmeID2)
        this.includes()
        this.isReady = true
      } /*else { window.location.replace('/') }*/
    }
  },
  includes () {
    window.addEventListener('DOMContentLoaded', function () {
      assets.add.script('/static/easy.qrcode.js')
      // console.log('LOADED QRCODE.js')
    })
  }
  /* name() {
    document.querySelectorAll("[data-corporate-name]").forEach((v, i) => {
      v.innerHTML = sitwithme_partners[sitwithme_slug].Title;
    });
    document.title = `McGrath Foundation | ${sitwithme_partners[sitwithme_slug].Title} – Pink Test 2024`;
  }, */
}

sitwithme.init()

function getID (path, part) {
  if (part <= 0) { return 0 }

  const parts = path.split('/').filter((a) => a)
  if (parts.length <= 1) { return 0 } // No IDs
  // console.log(parts)

  const ids = parts[1].split('-').filter((a) => a)
  if (ids.length < part) { return 0 } // Out of index range
  // console.log(ids[part - 1])

  return ids[part - 1]
}

export function setID2 (id) {
  sitwithmeID2 = id
}
