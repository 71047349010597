import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { forEach, range } from 'lodash'
import cc from 'classcat'

import { util, _API_URI, _SHARE_IMAGE_URI, customiserutil } from './_util.js'

export const stickerLocations = {
  /*pin: {
    Single: [10, 720],
    Double: [10, 720],
    Multi3: [10, 720],
    Multi4: [10, 720],
    Multi5: [10, 720],
    Group: [10, 720],
    Ground: [10, 720]
  },*/
  mfSticker: {
    Single: [950, 950],
    Double: [950, 950],
    Multi3: [950, 950],
    Multi4: [950, 950],
    Multi5: [950, 950],
    Group: [950, 950],
    Ground: [950, 950]
  },  
  paSticker: {
    Single: [950, 950],
    Double: [950, 950],
    Multi3: [950, 950],
    Multi4: [950, 950],
    Multi5: [950, 950],
    Group: [950, 950],
    Ground: [950, 950]
  },
  auSticker: {
    Single: [950, 950],
    Double: [950, 950],
    Multi3: [950, 950],
    Multi4: [950, 950],
    Multi5: [950, 950],
    Group: [950, 950],
    Ground: [950, 950]
  }
}

export const stickerContent = {
  /*'McGrath Pin': {
    src: '/img/flairs/MCGRATH-PIN.webp',
    thumbnail: '/img/flairs/MCGRATH-PIN_THUMB.webp',
    locations: stickerLocations.pin,
  },*/
  'McGrath Sticker': {
    src: '/img/flairs/MCGRATH-CMON.webp',
    thumbnail: '/img/flairs/MCGRATH-CMON_THUMB.webp',
    locations: stickerLocations.mfSticker,
  },
  'Aussie Sticker': {
    src: '/img/flairs/AUSSIE-STICKER.webp',
    thumbnail: '/img/flairs/AUSSIE-STICKER_THUMB.webp',
    locations: stickerLocations.auSticker,
  },
  'Pakistan Sticker': {
    src: '/img/flairs/PAKISTAN-STICKER.webp',
    thumbnail: '/img/flairs/PAKISTAN-STICKER_THUMB.webp',
    locations: stickerLocations.paSticker,
  }
}

export function Thanks (props) {
  if (props.active) {
    return (
      <div className="p-4 bg-lblue text-purple text-center rounded">
        <h1>
          Thanks for your help in making this {' '}
          <span className="text-pink">One to remember</span>.
        </h1>
        <p>
          Vanilla ice cream is the most common and basic flavour of ice cream
          which is favourite amongst all.
        </p>
        <button
          className="btn btn-sm btn-primary font-weight-bold w-100"
          onClick={() => {
            props.setStage('customise')
          }}
        >
          &larr; Customise Seat More
        </button>
      </div>
    )
  } else {
    return null
  }
}

export function Accordion (props) {
  if (props.visible) {
    return (
      <div
        className={cc([
          'customiser__accordion',
          {
            active: props.active
          }
        ])}
      >
        <button
          className="customiser__accordion_header"
          onClick={() => {
            props.stateSetter(props.index)
          }}
        >
          {props.title}
          {props.active
            ? (
            <img src="/img/accordion/accordion--on.svg" />
              )
            : (
            <img src="/img/accordion/accordion--off.svg" />
              )}
        </button>
        <article
          className={cc([
            'customiser__accordion_content',
            {
              customiser__accordion_content_active: props.active
            }
          ])}
        >
          <div className="customiser__accordion_wrapper">{props.children}</div>
        </article>
      </div>
    )
  } else {
    return null
  }
}

export function getLines (ctx, phrase, maxPxLength, textStyle) {
  const wa = phrase.split(' ')
  const phraseArray = []
  let lastPhrase = wa[0]
  let measure = 0
  const splitChar = ' '

  // if (!wa.includes(" ")) return wa[0].match(new RegExp(".{1,20}", "g"));
  if (wa.length <= 1) return wa
  if (wa.length === 2) return wa

  ctx.font = textStyle

  for (let i = 1; i < wa.length; i++) {
    const w = wa[i]
    measure = ctx.measureText(lastPhrase + splitChar + w).width
    if (measure < maxPxLength) {
      lastPhrase += splitChar + w
    } else {
      phraseArray.push(lastPhrase)
      lastPhrase = w
    }
    if (i === wa.length - 1) {
      phraseArray.push(lastPhrase)
      break
    }
  }

  return phraseArray
}

const shareImages = {
  Single: [
    '/img/share/SHARE-SINGLE.png',
    '/img/share/SHARE-SINGLE-MESSAGE.png'
  ],
  Double: [
    '/img/share/SHARE-DOUBLE.png',
    '/img/share/SHARE-DOUBLE-MESSAGE.png'
  ],
  Multi3: [
    '/img/share/SHARE-MULTI3.png',
    '/img/share/SHARE-MULTI3-MESSAGE.png'
  ],
  Multi4: [
    '/img/share/SHARE-MULTI4.png',
    '/img/share/SHARE-MULTI4-MESSAGE.png'
  ],
  Multi5: [
    '/img/share/SHARE-MULTI5.png',
    '/img/share/SHARE-MULTI5-MESSAGE.png'
  ],
  Group: ['/img/share/SHARE-GROUP.png', '/img/share/SHARE-GROUP-MESSAGE.png']
}

const shareSocialImages = {
  Single: '/img/share/SHARE-SINGLE-SOCIAL.jpg',
  Double: '/img/share/SHARE-DOUBLE-SOCIAL.jpg',
  Multi3: '/img/share/SHARE-MULTI3-SOCIAL.jpg',
  Multi4: '/img/share/SHARE-MULTI4-SOCIAL.jpg',
  Multi5: '/img/share/SHARE-MULTI5-SOCIAL.jpg',
  Group: '/img/share/SHARE-GROUP-SOCIAL.jpg'
}

function App (props) {
  const urlParams = new URLSearchParams(window.location.search)
  const donationId = urlParams.get('did')
  const virtualSeats = parseInt(urlParams.get('vs'))
  const selectedSeat = parseInt(urlParams.get('seatimage'))
  const siteURI = process.env.SITE_URI
  const _default_sticker_params = { aus: 'Aussie Baggy', eng: 'English Flag' }
  const [activeTab, setActiveTab] = React.useState(2)
  const [cacheBuster, setCacheBuster] = React.useState('')
  const [isLoaded, setIsLoaded] = React.useState(false)
  const [init, setInit] = React.useState(false)
  const [primaryImageSaved, setPrimaryImageSaved] = React.useState(false)
  const [cswmImageSaved, setCSWMImageSaved] = React.useState(false)
  const [inappropriate, setInappropriate] = React.useState(false)
  const [isCSWM, setIsCSWM] = React.useState((urlParams.get('iscswm') !== null && urlParams.get('iscswm') === 'true') || false)
  
  const maxSeats = 0

  // Convert "Multiple" selection into appropriate start seat index
  const seatIndex = (selectedSeat === 3)
    ? 5
    : (selectedSeat === 2)
        ? (virtualSeats >= 5) ? 4 : (virtualSeats === 4) ? 3 : 2
        : selectedSeat

  const [seatImage, setSeatImage] = React.useState(
    [
      'Single',
      'Double',
      'Multi3',
      'Multi4',
      'Multi5',
      'Group'
    ][seatIndex || 0]
  )
  const [names, setNames] = React.useState(initNames)

  function initNames () {
    // const name = urlParams.get('fname') ? urlParams.get('fname') + ' ' + urlParams.get('lname') : ''
    let tempName = (urlParams.get('fname') ? urlParams.get('fname') : '').replace(/\+/g, '%20')
    const name = decodeURIComponent(tempName)
    if (isCSWM) {
      let tempFriendName = (urlParams.get('friend') ? urlParams.get('friend') : '').replace(/\+/g, '%20')
      const friend = decodeURIComponent(tempFriendName)
      return [friend, name]
    } else {
      return [name]
    }
  }

  const [messageInput, setMessageInput] = React.useState('')
  const [message, setMessage] = React.useState('')
  const [messageSet, setMessageSet] = React.useState(false)
  const [personalisation, setPersonalisation] = React.useState(
    urlParams.get('personalisation') || 1
  )
  const [sticker, setSticker] = React.useState(
    _default_sticker_params[urlParams.get('supporting')] || ''
  )

  const nameSizes = {
    Single: 1,
    Double: 2,
    Multi3: 3,
    Multi4: 4,
    Multi5: 5,
    Group: 1
  }
  const [activeNames, setActiveNames] = React.useState(
    Object.values(nameSizes)[seatIndex]
  )

  const collectRefs = []
  const canvasRef = React.useRef(null)
  const canvasSocialRef = React.useRef(null)
  const canvasInviteRef = React.useRef(null)
  const canvasInviteSocialRef = React.useRef(null)

  function refreshImage () {
    const canvasObj = canvasRef.current
    const ctx = canvasObj.getContext('2d')
    const canvasSocialObj = canvasSocialRef.current
    const ctx_social = canvasSocialObj.getContext('2d')
    const canvasInviteObj = canvasInviteRef.current
    const ctx_invite = canvasInviteObj.getContext('2d')
    const canvasInviteSocialObj = canvasInviteSocialRef.current
    const ctx_invite_social = canvasInviteSocialObj.getContext('2d')

    canvasObj.width = 1080
    canvasObj.height = 1080 //message === '' ? 1080 : 1350
    canvasSocialObj.width = 1200
    canvasSocialObj.height = 628
    canvasInviteObj.width = 1080
    canvasInviteObj.height = 1080
    canvasInviteSocialObj.width = 1200
    canvasInviteSocialObj.height = 628

    ctx.clearRect(0, 0, canvasObj.width, canvasObj.height)
    ctx.translate(ctx.canvas.width * 0.5, ctx.canvas.height * 0.5);    // center

    const font_style = 'Bold 40px Linotte'
    const font_style_md = 'Bold 32px Linotte'
    const font_style_sm = 'Bold 24px Linotte'
    const font_style_share = 'Bold Italic 32px Linotte'

    const base_image = new Image()
    base_image.src = shareImages[seatImage][0] //shareImages[seatImage][message === '' ? 0 : 1]
    base_image.onload = function () {
      
      ctx.drawImage(base_image, 0, 0)
      ctx.rotate(-3.5 * Math.PI/180);   // 3.5°
      ctx.font = seatImage === 'Double' || seatImage === 'Group' ? font_style_md : seatImage.indexOf('Multi') >= 0 ? font_style_sm : font_style
      ctx.fillStyle = '#fff'

      if (names) {
        customiserutil.names(ctx, canvasObj, getLines, names, seatImage)
      }
      if (sticker) {
        customiserutil.sticker(
          ctx,
          canvasObj,
          sticker,
          seatImage,
          stickerContent,
          0.8
        )
      }

      if (message) {
        // Main text
        getLines(ctx, message, 550, font_style_share).forEach(function (v, i) {
          ctx.font = font_style_share
          ctx.fillStyle = '#3e1a3b'
          ctx.fillText(
            v,
            (canvasObj.width / 2 - ctx.measureText(v).width / 2) - 40,
            360 + 44 * i
          )
        })
      }

      if (!init) setInit(true)
    }
     ctx.translate(-ctx.canvas.width * 0.5, -ctx.canvas.height * 0.5);  // reset 


    // Social
    ctx_social.clearRect(0, 0, canvasSocialObj.width, canvasSocialObj.height)
    ctx_social.translate(ctx_social.canvas.width * 0.5, ctx_social.canvas.height * 0.5);    // center

    const social_image = new Image()
    social_image.src = shareSocialImages[seatImage]
    social_image.onload = function () {
      const font_style = 'Bold 24px Linotte'
      const font_style_sm = 'Bold 16px Linotte'

      ctx_social.drawImage(social_image, 0, 0)
      ctx_social.rotate(-3.5 * Math.PI/180);   // 3.5°
      ctx_social.font = seatImage.indexOf('Multi') >= 0 || seatImage === 'Group' ? font_style_sm : font_style
      ctx_social.fillStyle = '#fff'

      if (names) {
        customiserutil.socialnames(
          ctx_social,
          canvasSocialObj,
          getLines,
          names,
          seatImage
        )
      }
    }
    ctx_social.translate(-ctx_social.canvas.width * 0.5, -ctx_social.canvas.height * 0.5);  // reset 

    // Invite
    ctx_invite.clearRect(0, 0, canvasInviteObj.width, canvasInviteObj.height)
    ctx_invite.translate(ctx_invite.canvas.width * 0.5, ctx_invite.canvas.height * 0.5);    // center

    const invite_image = new Image()
    invite_image.src = shareImages.Double[0]
    invite_image.onload = function () {
      const font_style = 'Bold 32px Linotte'

      ctx_invite.drawImage(invite_image, 0, 0)
      ctx_invite.rotate(-3.5 * Math.PI/180);   // 3.5°
      ctx_invite.font = font_style
      ctx_invite.fillStyle = '#fff'

      if (names) {
        const invite_names = isCSWM ? [names[1], 'Your Mate'] : [names[0], 'Your Mate']
        customiserutil.names(ctx_invite, canvasInviteObj, getLines, invite_names, 'Double', -3)
      }
     
      if (virtualSeats === 1) {
        setInviteImage()    // Update invite image
      }
    }
    ctx_invite.translate(-ctx_invite.canvas.width * 0.5, -ctx_invite.canvas.height * 0.5);  // reset 



    // Invite Social
    ctx_invite_social.clearRect(0, 0, canvasInviteSocialObj.width, canvasInviteSocialObj.height)
    ctx_invite_social.translate(ctx_invite_social.canvas.width * 0.5, ctx_invite_social.canvas.height * 0.5);    // center

    const invite_social_image = new Image()
    invite_social_image.src = shareSocialImages.Double
    invite_social_image.onload = function () {
      const font_style = 'Bold 24px Linotte'

      ctx_invite_social.drawImage(invite_social_image, 0, 0)
      ctx_invite_social.rotate(-3.5 * Math.PI/180);   // 3.5°
      ctx_invite_social.font = font_style
      ctx_invite_social.fillStyle = '#fff'

      if (names) {
        const invite_social_names = isCSWM ? [names[1], 'Your Mate'] : [names[0], 'Your Mate']
        customiserutil.socialnames(ctx_invite_social, canvasInviteSocialObj, getLines, invite_social_names, 'Double')
      }
    }
    ctx_invite_social.translate(-ctx_invite_social.canvas.width * 0.5, -ctx_invite_social.canvas.height * 0.5);  // reset 

    setCacheBuster(Date.now())
  }

  useEffect(() => {
    _saveImageInit()
  }, [init])

  useEffect(() => {
    _saveImageCSWMInit()
  }, [primaryImageSaved])

  function setInviteImage(to) {

    var to = setInterval(() => {
      const inviteMatesImg = document.getElementById("inviteMatesImg")
      const inviteLoading = document.querySelector(".invite-mates-container .loading")
      if (inviteMatesImg !== null && inviteLoading !== null) {
        inviteMatesImg.setAttribute("src", canvasInviteRef.current.toDataURL());
        inviteLoading.classList.add("d-none");
        clearTimeout(to)
      }
    }, 1000)
    
  }

  function _setSeatImage (input) {
    setSeatImage(input)
  }

  function _setSticker (input) {
    if (input === sticker) setSticker('')
    else setSticker(input)
  }

  function _setActive (input) {
    setNames(
      collectRefs.map((e) => {
        return e.value
      })
    )
    const nonnull = collectRefs.filter(elements => {
      return (elements != null && elements !== undefined && elements !== '')
    })
    _setActiveNames(nonnull.length)
  }

  function _addName () {
    _setActiveNames(activeNames + 1)
    const img = ['Single', 'Double', 'Multi3', 'Multi4', 'Multi5', 'Group']
    const idx = img.indexOf(seatImage) + 1
    _setSeatImage(img[idx])
  }
  function _removeName () {
    _setActiveNames(activeNames - 1)
    const img = ['Single', 'Double', 'Multi3', 'Multi4', 'Multi5', 'Group']
    const idx = img.indexOf(seatImage) - 1
    _setSeatImage(img[idx])
  }

  function _setActiveNames (input) {
    setActiveNames(input)
  }

  const setMessageDebounced = React.useRef(_.debounce(setMessage, 1500))
  function _setMessage (input) {
    setMessageInput(input.target.value)
    setMessageDebounced.current(input.target.value)
  }

  function _setMessageSet (val) {
    setMessageSet(val)
  }

  /*const setImageFixDebounced = React.useRef(_.debounce(refreshImage, 1500))
  function _setMessage (input) {
    refreshImage()
    setImageFixDebounced.current()
  }*/

  function _showOptions (e) {
    e.preventDefault()
    const optionsElement = document.querySelector('.customiser__options')
    const saveElement = document.querySelector('.customiser__save')
    const shareElement = document.querySelector('.customiser__share')

    optionsElement.classList.remove('d-none')
    saveElement.classList.remove('d-none')
    shareElement.classList.add('d-none')
    setSaveAndShare(false)
  }

  function setSaveAndShare (isSaving) {
    const donePersonalising = document.querySelector('.done-personalising')
    const donePersonalisingIcon = document.querySelector('.done-personalising i')
    if (isSaving) {
      donePersonalisingIcon.classList.remove('fa-share')
      donePersonalisingIcon.classList.add('fa-spinner', 'fa-spin')
      donePersonalising.classList.add('bg-secondary')
      donePersonalising.childNodes[1].nodeValue = 'Saving...'
    } else {
      donePersonalisingIcon.classList.remove('fa-spinner', 'fa-spin')
      donePersonalisingIcon.classList.add('fa-share')
      donePersonalising.classList.remove('bg-secondary')
      donePersonalising.childNodes[1].nodeValue = 'I\'ve Finished Personalising!'
    }
  }

  function _saveAndShowShare (e) {
    if (e) {
      e.preventDefault()
    }

    setSaveAndShare(true)

    const optionsElement = document.querySelector('.customiser__options')
    const saveElement = document.querySelector('.customiser__save')
    const shareElement = document.querySelector('.customiser__share')

    const isVIP = util.getParam('isvip')
    const did = util.getParam('did')
    const imageData = canvasRef.current
      .toDataURL('image/jpeg', 0.8)
      .replace('data:image/jpeg;base64,', '')
    const imageDataSocial = canvasSocialRef.current
      .toDataURL('image/jpeg', 0.75)
      .replace('data:image/jpeg;base64,', '')
    const imageInviteData = canvasInviteRef.current
      .toDataURL('image/jpeg', 0.75)
      .replace('data:image/jpeg;base64,', '')
    const imageInviteSocialData = canvasInviteSocialRef.current
      .toDataURL('image/jpeg', 0.75)
      .replace('data:image/jpeg;base64,', '')

    if (canvasRef.current.width !== 1080) {
      return
    }

    const saveShareUrl =
        process.env.API_ENDPOINT_SHARE + '?cachebuster=' + cacheBuster
    fetch(saveShareUrl, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        Id: did,
        ImageType: 'share',
        Data: imageData,
        DataSocial: imageDataSocial
      })
    }).then(() => {
      customiserutil.savePersonalisation(donationId, 'public', {
        SeatType: seatImage,
        Seats: names,
        Supporting: '',
        Message: message || '',
        Sticker: sticker
      })

      // Save default CSWM share image
      const saveCSWMShareUrl = process.env.API_ENDPOINT_SITWITHMESHAREDEFAULT + '?cachebuster=' + cacheBuster
      fetch(saveCSWMShareUrl, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          Id: did,
          ImageType: 'cswm-share',
          Data: imageInviteData,
          DataSocial: imageInviteSocialData
        })
      }).then(() => {
        optionsElement.classList.add('d-none')
        saveElement.classList.add('d-none')
        shareElement.classList.remove('d-none')
        setSaveAndShare(false)
      })
    })

    if (isVIP !== undefined && isVIP === 'true') {
      customiserutil.processVIP(donationId, {
        SeatType: seatImage,
        Seats: names,
        Supporting: '',
        Message: message || '',
        Sticker: sticker
      })
    }
  }

  function _saveImageInit () {
    console.log('_saveImageInit')
    const did = util.getParam('did')
    const imageData = canvasRef.current
      .toDataURL('image/jpeg', 0.8)
      .replace('data:image/jpeg;base64,', '')
    const imageDataSocial = canvasSocialRef.current
      .toDataURL('image/jpeg', 0.75)
      .replace('data:image/jpeg;base64,', '')

    if (canvasRef.current.width !== 1080) {
      return
    }

    customiserutil.savePersonalisation(donationId, 'public', {
      SeatType: seatImage,
      Seats: names,
      Supporting: util.getParam('supporting'),
      Message: message || '',
      Sticker: sticker
    })

    if (!primaryImageSaved) {
      const saveShareUrl =
      process.env.API_ENDPOINT_SHARE + '?cachebuster=' + cacheBuster
      fetch(saveShareUrl, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          Id: did,
          ImageType: 'share',
          Data: imageData,
          DataSocial: imageDataSocial
        })
      })
        .then((response) => {
          if (response.ok) { setPrimaryImageSaved(true) }
        })
        console.log('_saveImageInit SAVED')
    }

  }

  function _saveImageCSWMInit () {
    console.log('_saveImageCSWMInit')
    const did = util.getParam('did')

    if (canvasRef.current.width !== 1080) {
      return
    }

    if (primaryImageSaved && !cswmImageSaved) {
      const imageInviteData = canvasInviteRef.current
        .toDataURL('image/jpeg', 0.75)
        .replace('data:image/jpeg;base64,', '')
      const imageInviteSocialData = canvasInviteSocialRef.current
        .toDataURL('image/jpeg', 0.75)
        .replace('data:image/jpeg;base64,', '')

      const saveCSWMShareUrl = process.env.API_ENDPOINT_SITWITHMESHAREDEFAULT + '?cachebuster=' + cacheBuster
      fetch(saveCSWMShareUrl, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          Id: did,
          ImageType: 'cswm-share',
          Data: imageInviteData,
          DataSocial: imageInviteSocialData
        })
      })
        
      setCSWMImageSaved(true)

      console.log('_saveImageCSWMInit SAVED')
    }
  }


  function _saveImage (ev) {
    if (ev) {
      ev.preventDefault()

      /* const did = util.getParam('did')
       const imageData = canvasRef.current
        .toDataURL('image/jpeg', 0.8)
        .replace('data:image/jpeg;base64,', '')
      const imageDataSocial = canvasSocialRef.current
        .toDataURL('image/jpeg', 0.8)
        .replace('data:image/jpeg;base64,', '')

      if (canvasRef.current.width !== 1080) {
        return
      }

       const saveShareUrl =
        process.env.API_ENDPOINT_SHARE + '?cachebuster=' + cacheBuster
      fetch(saveShareUrl, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          Id: did,
          ImageType: 'share',
          Data: imageData,
          DataSocial: imageDataSocial
        })
      }).then(() => { */
      const is_safari =
          navigator.userAgent.indexOf('Safari') > -1 &&
          navigator.userAgent.indexOf('Chrome') === -1
      // const buttonClasses = ev.target.classList;
      if (!is_safari) {
        window
          .open(
            ev.target.getAttribute('href'),
            ev.target.getAttribute('target') || ''
          )
          .focus()
      } else {
        // var link = document.createElement("a");
        // link.download = "Pink Test 2024.jpg";
        // link.href = canvasRef.current.toDataURL("image/jpeg", 0.8);
        // link.click();
        window.location = ev.target.getAttribute('href')
      }

      // setTimeout(_showInvite, 250) // Turn this off as we have Invite button

      // document.querySelector('body').classList.add('noscroll')
      // document.querySelector("#modal-thankyou").style.display = "block";
      // document.querySelector("#modal-thankyou").classList.add("visible");

      /* }) */
    }
  }

  function _showInvite (ev) {
    if (ev) { ev.preventDefault() }
    const did = util.getParam('did')
    const inviteImg = document.getElementById('invite_image')
    const inviteEls = document.querySelectorAll('.invite-button-area, .invite-instructions')
    const inviteQRTextArea = document.querySelector('.invite-qrtext-area')

    if (navigator.canShare && window.innerWidth < 1024) {
      //inviteImg.setAttribute('src', '/img/share/' + did + '-0.jpg') // imageInviteData)
      inviteImg.setAttribute('src', _SHARE_IMAGE_URI + '/' + did + '-0.jpg') // imageInviteData)      
      inviteQRTextArea.classList.add('d-none')
      inviteEls.forEach((el) => { el.classList.remove('d-none') })
      const inviteBtns = document.querySelectorAll('#invite_button, #invite_button_pre')
      inviteBtns.forEach((inviteBtn) => {
        inviteBtn.addEventListener('click', (e) => {
          e.preventDefault()
          const shareElement = {
            url: `${siteURI}/share/sit-with-me/${did}-0`,
            title: 'Come Sit With Me - Help fund McGrath Breast Care Nurses -  NRMA Insurance Pink Test 2024',
            text: 'The NRMA Insurance Pink Test is back in 2024. No matter where you are, unite in pink to support families experiencing breast cancer.'
          }
          navigator.share(shareElement)
        })
      })
    } else {
      if (document.querySelector('#invite_qr_container canvas') === null) {
        util.generateQrCode('?invite=y&did=' + did, '#invite_qr_container')
      }
      inviteImg.classList.add('d-none')
      inviteQRTextArea.classList.remove('d-none')
      inviteEls.forEach((el) => { el.classList.add('d-none') })
    }

    document.querySelector('body').classList.add('noscroll')
    document.querySelector('#modal-sit-with-me').style.display = 'block'
    document.querySelector('#modal-sit-with-me').classList.add('visible')
  }

  // init
  /*React.useEffect(() => {
    refreshImage()
  }, [])*/

  // setData
  React.useEffect(() => {
    refreshImage()
  }, [seatImage, names, sticker])

  React.useEffect(() => {
    const profanityReqHeader = new Headers()
    profanityReqHeader.append('Content-Type', 'application/json')

    const profanityReqOptions = {
      method: 'GET',
      headers: profanityReqHeader,
      redirect: 'follow'
    }

    setInappropriate(false)

    if (messageInput !== '') {
      fetch(
        _API_URI +
          process.env.API_ENDPOINT_PROFANITYFILTER +
          '?msg=' +
          encodeURI(messageInput),
        profanityReqOptions
      )
        .then((response) => {
          if (response.ok) return response.json()
          throw new Error('Network Error.')
        })
        .then((response) => {
          if (response.profanity === false) {
            _setMessageSet(true)
            return refreshImage()            
          }
          throw new Error('Foul Message.')
        })
        .catch((error) => {
          console.error(error)
          setMessage('')
          setInappropriate(true)
        })
    } else {
      setMessage('')
      setInappropriate(false)
      if (messageSet) {
        refreshImage()
        _setMessageSet(false)
      }
    }
  }, [message])

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-6">
          { !init ? <span className="loading"><i className="far fa-sync fa-spin fa-2x"></i></span> : null }
          <div
            className={'embed-responsive embed-responsive-1by1'
              //message === ''
              //  ? 'embed-responsive embed-responsive-1by1'
              //  : 'embed-responsive embed-responsive-4by5'
            }
          >
            <canvas
              className="bg-pink embed-responsive-item rounded"
              ref={canvasRef}
            ></canvas>
          </div>
          <canvas
            className="d-none bg-pink rounded w-100"
            ref={canvasSocialRef}
          ></canvas>
          <div className='d-none embed-responsive embed-responsive-1by1'>
            <canvas
            className="bg-pink embed-responsive-item rounded"
            ref={canvasInviteRef}
            ></canvas>
          </div>
          <canvas
            className="d-none bg-pink rounded w-100"
            ref={canvasInviteSocialRef}
          ></canvas>
        </div>
        <div className="col-12 col-lg-6">
          <div className="customiser__options mb-4">
            <Accordion
              key="accordion2"
              title={seatImage === 'Group' ? 'Edit group name' : 'Edit name/s'}
              index={2}
              active={activeTab === 2}
              stateSetter={setActiveTab}
              visible={true}
            >
              <fieldset>
                {range(nameSizes[seatImage]).map((seatImg, i) => (
                  <>
                  <span className="seat-name-title d-block">Virtual Pink Seat Name {i+1}</span>
                  <div className="char-offset position-relative" key={seatImg}>
                    <input
                      className={"mt-1 mb-2" + 
                    (isCSWM && i === 0 ? ' disabled' : '')}
                      type="text"
                      value={names[seatImg] || ''}
                      id={i}
                      onChange={_setActive}
                      maxLength={25}
                      ref={(el) => {
                        collectRefs.push(el)
                      }}
                      disabled={isCSWM && i === 0}
                    />
                    <span className="char-limit">
                      {names[seatImg] ? names[seatImg].length : 0}/{25}
                    </span>
                    <button
                      key={'button_' + i}
                      title="Remove name"
                      className={
                        'btn name-remove' +
                        (i === activeNames - 1 && activeNames > 1 && !isCSWM
                          ? ''
                          : ' d-none')
                      }
                      onClick={() => {
                        _removeName()
                      }}
                    >
                      <i className="far fa-times"></i>
                    </button>
                  </div>
                  </>
                ))}
                <button
                  className={
                    'btn btn-link add-seat' +
                    (activeNames <= personalisation && activeNames < 5 && seatImage !== 'Group' && !isCSWM ? '' : ' d-none')
                  }
                  onClick={() => {
                    _addName()
                  }}
                >
                  <span>
                    <i className="far fa-plus"></i>
                  </span>{' '}
                  Add another seat
                </button>
                <br />
              </fieldset>
            </Accordion>
            <Accordion
              key="accordion1"
              title="Change seat image"
              index={1}
              active={activeTab === 1}
              stateSetter={setActiveTab}
              visible={!isCSWM}
            >
              <div
                className={cc([
                  'seats__wrapper',
                  { seats__fourcol: urlParams.get('vs') >= 4 }
                ])}
              >
                {[
                  'Single',
                  'Double' /* "Multiple", */,
                  'Multi3',
                  'Multi4',
                  'Multi5',
                  'Group'
                ].map((seatImg, i) => (
                  <button
                    key={seatImg}
                    className={cc([
                      'seats',
                      {
                        seats__active: seatImage === seatImg,
                        seats__disabled:
                          personalisation >= 3 && i === 5 ? false : personalisation < i,
                        'd-none':
                          (seatImage === 'Single' && (seatImg === 'Multi4' || seatImg === 'Multi5')) ||
                          (seatImage === 'Double' && (seatImg === 'Multi4' || seatImg === 'Multi5')) ||
                          (seatImage === 'Multi3' && (seatImg === 'Multi4' || seatImg === 'Multi5')) ||
                          (seatImage === 'Multi4' && (seatImg === 'Multi3' || seatImg === 'Multi5')) ||
                          (seatImage === 'Multi5' && (seatImg === 'Multi3' || seatImg === 'Multi4')) ||
                          (seatImage === 'Group' && (seatImg === 'Multi4' || seatImg === 'Multi5'))
                      }
                    ])}
                    onClick={() => {
                      if ((personalisation >= 3 && i === 5) || personalisation >= i) {
                        _setSeatImage(seatImg)
                        _setActiveNames(nameSizes[seatImg])
                      }
                    }}
                  >
                    <img
                      src={`img/radio/radio--${seatImg.toLowerCase()}.webp`}
                    />
                    <span className="seats__title">{seatImg === 'Multi3' || seatImg === 'Multi4' || seatImg === 'Multi5' ? 'Multiple' : seatImg}</span>
                  </button>
                ))}
              </div>
            </Accordion>            
            <Accordion
              key="accordion3"
              title="Add a personal message"
              index={3}
              active={activeTab === 3}
              stateSetter={setActiveTab}
              visible={true}
            >
              <div className="char-offset position-relative">
                <input
                  type="text"
                  value={messageInput}
                  onChange={_setMessage}
                  maxLength={60}
                />
                <span className="char-limit message">
                  {messageInput.length}/{60}
                </span>
              </div>
              {inappropriate
                ? (
                <div className="text-danger p-3">
                  Please review your input to ensure it doesn't contain
                  foul/inappropriate language.
                </div>
                  )
                : null}
            </Accordion>
            <Accordion
              key="accordion4"
              title="Add a sticker"
              index={4}
              active={activeTab === 4}
              stateSetter={setActiveTab}
              visible={true}
            >
              <div className="sticker__wrapper">
                {Object.keys(stickerContent).map((v) => (
                  <button
                    className={
                      sticker === v ? 'sticker sticker__active' : 'sticker'
                    }
                    key={v}
                    onClick={() => _setSticker(v)}
                    title={v}
                  >
                    <img
                      src={stickerContent[v].thumbnail}
                      className="sticker__thumb"
                    />
                  </button>
                ))}
              </div>
            </Accordion>
          </div>
          <div className="mb-4 customiser__save text-center font-weight-bold">
            <p className="mx-0 mx-lg-4">
              Done personalising? Click the button below to download and share your seats.
            </p>
            <div className="social-share virtual-seats text-left">
              <ul className="row">
                <li className="col-12">
                  <a
                    className="social-item dl mb my-2 done-personalising btn"
                    href="#"
                    onClick={_saveAndShowShare}
                  >
                    <i className="fas fa-share"></i> I've Finished Personalising!
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="mb-4 customiser__share text-center font-weight-bold d-none">
            <p className="mx-0 mx-lg-4">
              Unite in pink this NRMA Insurance Pink Test! Share your
              Virtual Pink Seats with your family and friends.
            </p>
            <p>
              Don't forget to tag{' '}
              <span className="text-pink">#PinkTest</span> and{' '}
              <span className="text-pink">
                <a
                  href="https://www.instagram.com/mcgrathfoundation/?hl=en"
                  target="_blank"
                >
                  @mcgrathfoundation
                </a>
              </span>
            </p>
            <div className="social-share virtual-seats text-left">
              <ul className="row">
              {navigator.canShare && window.innerWidth < 1024
                ? (
                  <li className="col-12">
                    <button
                      className="social-item ntv mb my-2 btn"
                      onClick={() => {
                        canvasRef.current.toBlob((blob) => {
                          const shareElement = {
                            url: `${siteURI}/share/${donationId}`,
                            title:
                              'Help fund McGrath Breast Care Nurses -  NRMA Insurance Pink Test 2024',
                            text: 'The NRMA Insurance Pink Test is back in 2024. No matter where you are, unite in pink to support families experiencing breast cancer.',
                            files: [
                              new File([blob], 'Pink Test 2024.jpg', {
                                type: blob.type,
                                lastModified: new Date().getTime()
                              })
                            ]
                          }
                          navigator.share(shareElement)
                        })
                      }}
                    >
                      <i className="fas fa-share-alt"></i>
                      Save &amp; Share Now
                    </button>
                  </li>
                  )
                : null}
                <li className="col-12">
                  <a
                    className="social-item dl mb my-2 btn"
                    href={
                      _SHARE_IMAGE_URI + '/' +
                      donationId +
                      '.jpg?cachebuster=' +
                      cacheBuster
                    }
                    target="_blank"
                    onClick={_saveImage}
                  >
                    <i className="fas fa-download"></i> Download
                  </a>
                </li>
                {(!navigator.canShare && donationId) ||
                (window.innerWidth > 1024 && donationId)
                  ? (
                  <>
                    <li className="col-12 col-lg-6">
                      <a
                        className="social-item fb my-2"
                        href={`https://www.facebook.com/sharer/?u=${siteURI}/share/${donationId}`}
                        target="_blank"
                        onClick={_saveImage}
                      >
                        Facebook <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    {/* <li className="col-12 col-lg-6">
                      <a
                        className="social-item tw my-2"
                        href="https://twitter.com/share"
                        data-url={`${siteURI}/share/${donationId}`}
                        data-related="McGrathFdn"
                        data-text="I donated to make a difference!"
                        data-dnt="true"
                        target="_blank"
                        onClick={_saveImage}
                      >
                        Twitter <i className="fab fa-twitter"></i>
                      </a>
                    </li> */}
                    <li className="col-12 col-lg-6">
                      <a
                        className="social-item li my-2"
                        href={`https://www.linkedin.com/sharing/share-offsite/?url=${siteURI}/share/${donationId}`}
                        data-url={`${siteURI}/share/${donationId}`}
                        data-related="mcgrath-foundation"
                        data-text="I donated to make a difference!"
                        data-dnt="true"
                        target="_blank"
                        onClick={_saveImage}
                      >
                        LinkedIn <i className="fab fa-linkedin"></i>
                      </a>
                    </li>                    
                  </>
                    )
                  : null}
                <li className="col-12">
                  <a
                    className="social-item dl mb my-2 invite-friends-btn btn"
                    href={
                      '/?invite=y&did=' + donationId
                    }
                    target="_blank"
                    onClick={_showInvite}
                  >
                    <i className="fas fa-users"></i> Invite friends
                  </a>
                </li>
              </ul>
              {/* <script
                async=""
                src="https://platform.twitter.com/widgets.js"
                charSet="utf-8"
              ></script> */}
            </div>
            <p className="mt-4 mx-0 mx-lg-0">
              <a href="#" onClick={_showOptions} className="continue-personalising">
                &laquo; Hey, I'm not finished customising yet!
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

if (document.querySelector('#customiser')) {
  const root = ReactDOM.createRoot(document.querySelector('#customiser'))
  root.render(<App />)
  // ReactDOM.render(<App />, document.querySelector("#customiser"));
}
