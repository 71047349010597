import { util, assets, include } from './_util.js'
import { donate } from './donate.js'
import Glide from '@glidejs/glide'

let isExperienceLoaded = false
let defaultTab = ''

function experience () {
  const preloader = document.querySelector('.experience-placeholder')
  preloader.parentNode.removeChild(preloader)
  assets.add.script('/static/stackla.min.js')
}

function carousel () {
  new Glide('.carousel', {
    gap: 0,
    hoverpause: true,
    autoplay: 5000
  }).mount()
}

const toggles = {
  init () {
    if (location.pathname === '/') {
      defaultTab =
        util.getParam('donate') !== undefined
          ? 'donate'
          : util.getParam('virtual-seats') !== undefined
            ? 'virtual-seats'
            : defaultTab
      this.tab(defaultTab)
      this.form()
      this.tooltip()
      this.image()
    }
    this.modal()
  },
  modal () {
    document.querySelectorAll('[data-toggle=modal]').forEach(function (item) {
      item.addEventListener('click', function () {
        document.querySelector('body').classList.add('noscroll')
        document.querySelector('#modal-' + item.dataset.modal).style.display =
          'block'
        document
          .querySelector('#modal-' + item.dataset.modal)
          .classList.add('visible')
      })
    })
    document
      .querySelectorAll('[data-close=modal], .modal-tint')
      .forEach(function (item) {
        item.addEventListener('click', function () {
          document.querySelector('body').classList.remove('noscroll')
          this.closest('.modal').classList.remove('visible')
          this.closest('.modal').style.display = 'none'
        })
      })
  },
  tab (defaultTab) {
    document.querySelectorAll('[data-toggle=tab]').forEach(function (item) {
      item.addEventListener('click', function () {
        document.querySelectorAll('[data-toggle=tab]').forEach(function (item) {
          if (item.classList.contains('active')) {
            item.classList.remove('active')
          }
        })
        const attribute = item.attributes.id.value
        if (isExperienceLoaded === false) {
          if (attribute === 'experience') {
            include.get('carousel', carousel)
            include.get('experience', experience)
            isExperienceLoaded = true
          }
        }
        const active = document.querySelectorAll('.content .active')[0]
        const tab = document.querySelector('[tab-id=' + attribute + ']')
        active.classList.remove('active')
        item.classList.add('active')
        tab.classList.add('active')
        donate.setType(attribute)
      })
    })
    // Default/Vanity URL param set
    if (defaultTab !== '') {
      document
        .querySelectorAll('.content .active')[0]
        .classList.remove('active')
      document
        .querySelectorAll('[data-toggle=tab]')[0]
        .classList.remove('active')
      document.getElementById(defaultTab).classList.add('active')
      document
        .querySelector('[tab-id=' + defaultTab + ']')
        .classList.add('active')
      donate.setType(defaultTab)
    }
  },
  image () {
    document
      .querySelectorAll('[data-toggle=image-hover]')
      .forEach((element) => {
        let onevent = 'mousemove'
        let offevent = 'mouseout'
        if (!window.mobile) {
          onevent = 'click'
          offevent = 'scroll'
        }
        element.addEventListener(onevent, function (e) {
          const image = this.parentNode.querySelector('.hover-image')
          image.style.left = (e.clientX - image.width / 2).toString() + 'px'
          image.style.top = (e.clientY - image.height - 10).toString() + 'px'
          image.style.opacity = '1'
        })
        element.addEventListener(offevent, function (e) {
          const image = this.parentNode.querySelector('.hover-image')
          image.style.opacity = '0'
          setTimeout(function () {
            image.style.left = '-100%'
            image.style.top = '-100%'
          }, 1000)
        })
      })
    document.querySelectorAll('.hover-image').forEach((element) => {
      element.addEventListener('mousemove', function (e) {
        e.preventDefault()
      })
    })
  },
  form () {
    document.querySelectorAll('[data-toggle=form]').forEach(function (item) {
      // const postcode = document.querySelector('.postcode-container').innerHTML
      item.addEventListener('click', function () {
        const active = document.querySelectorAll('.donate-type .btn.active')[0]
        const isCC = item.attributes.id.value === 'cc'
        const isPP = item.attributes.id.value === 'pp'
        const isMobile = item.attributes.id.value === 'ap' || item.attributes.id.value === 'gp'
        if (active !== undefined) {
          active.classList.remove('active')
        }
        item.classList.add('active')

        document.querySelectorAll('.cc-only').forEach(function (item) {
          item.style.display = isCC ? 'block' : 'none'
          item.querySelectorAll('input,select').forEach(function (formfield) {
            formfield.removeAttribute(isCC ? 'disabled' : 'enabled')
            formfield.setAttribute(isCC ? 'enabled' : 'disabled', '')
            if (!isCC) {
              formfield.removeAttribute('invalid')
            }
          })
        })

        const selector = '.donate-' + (isCC ? 'card' : 'user')
        const finalSelector = '.donate-final'
        if (isCC) {
          document.querySelector(selector).classList.remove('d-none')
          document.querySelector(finalSelector).classList.remove('d-none')
          document
            .querySelector('.donate-type')
            .scrollIntoView({ block: 'start', behavior: 'smooth' })
        } else if (isMobile) {
          document.querySelector(selector).classList.remove('d-none')
          document.querySelector(finalSelector).classList.add('d-none')
          document
            .querySelector('.donate-type')
            .scrollIntoView({ block: 'start', behavior: 'smooth' })
        } else {
          document.querySelector('.donate-card').classList.add('d-none')
          document.querySelector(finalSelector).classList.add('d-none')
          document
            .querySelector(selector)
            .scrollIntoView({ block: 'start', behavior: 'smooth' })
        }

        document.querySelector('button[type=submit]').innerHTML = isCC
          ? 'Buy Now'
          : 'Go to PayPal'

        /* const postcodeSelector = '.postcode-container' + (isCC ? '-pp' : '')
        const postcodeSelectorNew = '.postcode-container' + (isCC ? '' : '-pp')
        const pcVal = document.querySelector(postcodeSelector).innerHTML
        const pcValNew = document.querySelector(postcodeSelectorNew).innerHTML
        if (pcVal !== '' || pcValNew !== '') {
          if (pcVal !== pcValNew) {
            if (isCC) { // To CC
              pcVal.innerHTML = pcValNew.innerHTML !== '' ? pcValNew.innerHTML : pcVal.innerHTML
              pcValNew.innerHTML = pcVal.innerHTML
            } else { // To PP/Other
              pcValNew.innerHTML = pcVal.innerHTML !== '' ? pcVal.innerHTML : pcValNew.innerHTML
              pcVal.innerHTML = pcValNew.innerHTML
            }
          }
        } */
        document.querySelector('[data-toggle="donation-name"]').innerHTML = isCC
          ? 'Name'
          : 'Name for tax receipt. <em>You can edit name on seat(s) after payment</em>'

        if (isPP) {
          document.querySelector('button[type=submit]').click()
        }
      })
    })
  },
  tooltip () {
    document.querySelectorAll('[data-toggle=tooltip]').forEach(function (item) {
      item.addEventListener('mouseenter', function (e) {
        e.preventDefault()
      })
      item.addEventListener('mouseover', function () {
        const placeholder = document.createElement('div')
        placeholder.classList.add('tooltip')
        placeholder.innerHTML = item.attributes.title.value
        item.prepend(placeholder)
      })
      item.addEventListener('mouseout', function () {
        if (item.querySelector('.tooltip')) {
          item.querySelector('.tooltip').remove()
        }
      })
    })
  }
}

toggles.init()
